import { ButtonHTMLAttributes, FC, SyntheticEvent } from 'react';
import clsx from 'clsx';

import classes from './UnderlinedButton.module.scss';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void;
}

const UnderlinedButton: FC<IProps> = ({ label, onClick, className, disabled, ...rest }) => {
  const handleChange = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button
      className={clsx(classes.button, className)}
      onClick={handleChange}
      disabled={disabled}
      aria-label={label}
      {...rest}
    >
      {label}
    </button>
  );
};

export { UnderlinedButton };
