import { FC } from 'react';
import clsx from 'clsx';

import classes from './FormError.module.scss';

interface IProps {
  message?: string;
  className?: string;
}

const FormError: FC<IProps> = ({ message, className }) => {
  return message ? <p className={clsx(classes.error, className)}>{message}</p> : null;
};

export { FormError };
