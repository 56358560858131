import { BaseHTMLAttributes, FC } from 'react';
import clsx from 'clsx';

import { ICONS } from './icons';
import Flower from './svgs/flower.svg';

import classes from './Icon.module.scss';

interface IProps extends BaseHTMLAttributes<HTMLDivElement> {
  type: keyof typeof ICONS;
  size?: number;
  width?: number | string;
  height?: number | string;
  offset?: number | string;
  leftOffset?: number | string;
}

const Icon: FC<IProps> = ({ type, width, height, size, className, offset, leftOffset, style, onClick }) => {
  const IconTag = ICONS[type] || Flower;
  return (
    <div
      className={clsx(classes.icon, { [className as string]: className })}
      style={{
        marginRight: offset || 0,
        marginLeft: leftOffset || 0,
        ...(size || width ? { width: size || width } : {}),
        ...(size || height ? { height: size || height } : {}),
        ...(style || {})
      }}
      onClick={onClick}
    >
      <IconTag width="100%" height="100%" />
    </div>
  );
};

export { Icon };
