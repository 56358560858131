import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSession } from 'next-auth/react';

import { IChangePasswordFormValues, postChangePassword } from '@api/change-password';
import { ERROR_MESSAGES } from '@lib/error-messages';
import { ButtonFPot, FormError, InputPassword, Modal, UnderlinedButton } from '../../shared';

import classes from './ChangePasswordModal.module.scss';

const ChangePasswordModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [pending, setPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successChanged, setSuccessChanged] = useState(false);
  const { data: session } = useSession();
  const token = session?.user.bearer ?? '';

  const { handleSubmit, control } = useForm<IChangePasswordFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      currentPassword: '',
      newPassword: ''
    }
  });

  const closeChangePasswordModal = () => {
    setIsOpen(false);
    setPending(false);
    setSuccessChanged(false);
    setErrorMessage('');
  };

  const openChangePasswordModal = () => {
    setIsOpen(true);
  };

  function handleChangePassword(formData: IChangePasswordFormValues) {
    setErrorMessage('');
    setPending(true);
    postChangePassword(formData, token)
      .then(() => {
        setSuccessChanged(true);
      })
      .catch((error) => {
        setErrorMessage(error.message || ERROR_MESSAGES.commonError);
      })
      .finally(() => setPending(false));
  }

  return (
    <>
      <Modal open={isOpen} closeModal={closeChangePasswordModal}>
        <h3 className={classes.dialogTitle}>{successChanged ? 'Пароль успішно змінено.' : 'Введіть новий пароль'}</h3>
        {!successChanged ? (
          <form onSubmit={handleSubmit(handleChangePassword)}>
            <Controller
              render={({ field: { value, onChange }, fieldState }) => (
                <InputPassword
                  label="Старий пароль"
                  id="currentPassword"
                  value={value}
                  onChange={onChange}
                  error={fieldState.invalid}
                  errorMessage="Пароль має містити принаймні 8 символів"
                />
              )}
              name="currentPassword"
              control={control}
              rules={{ required: true }}
            />
            <Controller
              render={({ field: { value, onChange }, fieldState }) => (
                <InputPassword
                  label="Новий пароль"
                  id="newPassword"
                  value={value}
                  onChange={onChange}
                  error={fieldState.invalid}
                  errorMessage="Пароль має містити принаймні 8 символів"
                />
              )}
              name="newPassword"
              control={control}
              rules={{ required: true }}
            />
            <FormError message={errorMessage} className={classes.error} />
            <ButtonFPot label="Змінити пароль" disabled={pending} spinner={pending} type="submit" fullWidth />
          </form>
        ) : (
          <ButtonFPot label="Закрити" onClick={closeChangePasswordModal} fullWidth />
        )}
      </Modal>
      <UnderlinedButton onClick={openChangePasswordModal} disabled={false} label="Змінити пароль" />
    </>
  );
};

export { ChangePasswordModal };
