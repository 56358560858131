import { ChangeEvent, FC } from 'react';
import { FieldError } from 'react-hook-form';
import clsx from 'clsx';

import classes from './Input.module.scss';

interface IInputProps {
  onChange?: (event: string | ChangeEvent<Element>) => void;
  value?: string;
  classNameWrapper?: string;
  error?: boolean;
  errorMessage?: FieldError | string;
  label: string;
  type?: string;
  id: string;
}

const Input: FC<IInputProps> = ({
  classNameWrapper,
  value,
  onChange,
  id,
  error,
  errorMessage,
  label,
  type,
  ...rest
}) => {
  const errorText = typeof errorMessage === 'string' ? errorMessage : errorMessage?.message;
  return (
    <div className={clsx(classes.inputBox, classNameWrapper)}>
      <input
        type={type || 'text'}
        value={value}
        onChange={onChange}
        className={clsx(classes.inputField, { [classes.fieldError]: error })}
        id={id}
        {...rest}
      />
      {error && <p className={classes.error}>{errorText}</p>}
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
export { Input };
