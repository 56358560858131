import { FC, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import parse from 'autosuggest-highlight/parse';
import clsx from 'clsx';
import _debounce from 'lodash/debounce';
import { Autocomplete, TextField } from '@mui/material';

import { CircularProgress } from '@components';
import { GOOGLE_MAPS_API_KEY } from '@lib/constants/environment';
import { IAutocompleteService, IPlaceType, IRequestPayload } from '@types';

import classes from './AddressAutocomplete.module.scss';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

function getAddressBuilding(addr?: string): string {
  if (!addr) return '';
  const addrArray = addr.split(', ');
  const build = addrArray[addrArray.length - 1];
  if (build) return build;
  return '';
}

const autocompleteService: { current: IAutocompleteService | null } = { current: null };

interface IProps {
  address?: string | IPlaceType;
  setAddress: (address: IPlaceType) => void;
  city: string;
  inputLabel?: string;
  error?: boolean;
  presetBuilding?: (building: string) => void;
  inputClassName?: string;
}

const AddressAutocomplete: FC<IProps> = ({
  inputLabel,
  address,
  setAddress,
  error,
  presetBuilding,
  city,
  inputClassName
}) => {
  const [value, setValue] = useState<IPlaceType | string | undefined>(address);
  const [inputValue, setInputValue] = useState(
    (address && typeof address !== 'string' ? address.description : '') || ''
  );
  const [options, setOptions] = useState<Array<IPlaceType | string>>([]);
  const [loading, setLoading] = useState(false);
  const loaded = useRef(false);
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetchRequest = useMemo(
    () =>
      _debounce((request: IRequestPayload, callback: (results?: IPlaceType[]) => void) => {
        if (autocompleteService.current && autocompleteService.current.getPredictions) {
          autocompleteService.current.getPredictions(request, callback);
        }
      }, 400),
    []
  );

  useEffect(() => {
    if (inputValue.length < 3) return;

    let active = true;
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) return;
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      setLoading(false);
      return;
    }

    setLoading(true);
    fetchRequest(
      {
        input: `${city}, ${inputValue}`,
        language: 'uk',
        componentRestrictions: { country: ['ua'] },
        types: ['address']
      },
      (results?: IPlaceType[]) => {
        if (active) {
          if (results) {
            setOptions(results);
          }
          setLoading(false);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [inputValue, fetchRequest]);

  // * trigger the location request on initial income address
  useEffect(() => {
    if (address && typeof address === 'string') {
      setTimeout(() => {
        setInputValue(address);
      }, 200);
    }
  }, []);

  const onChange = (_event: SyntheticEvent, newValue: IPlaceType | null) => {
    if (newValue && presetBuilding) {
      const building = getAddressBuilding(newValue.structured_formatting?.main_text);
      if (building && !isNaN(parseInt(building))) {
        presetBuilding(building);
        const modifiedValue = {
          ...newValue,
          structured_formatting: {
            ...newValue.structured_formatting,
            main_text: newValue.structured_formatting?.main_text.replace(`, ${building}`, '')
          }
        };
        setOptions(modifiedValue ? [modifiedValue, ...options] : options);
        setValue(modifiedValue);
        setAddress(modifiedValue);
        return;
      }
      setOptions(newValue ? [newValue, ...options] : options);
      setValue(newValue);
      setAddress(newValue);
    }
  };

  const onInputChange = (_event: SyntheticEvent, newInputValue: string) => {
    if (newInputValue.length < 3) return;
    setInputValue(newInputValue);
  };

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options as IPlaceType[]}
      autoComplete
      openOnFocus
      includeInputInList
      filterSelectedOptions
      value={value as IPlaceType}
      classes={{ inputRoot: clsx(classes.inputRoot, inputClassName), listbox: classes.listbox, root: inputClassName }}
      noOptionsText="Немає результатів"
      onChange={onChange}
      onInputChange={onInputChange}
      renderInput={(params) => (
        <TextField
          label={inputLabel || 'Введіть адресу'}
          {...params}
          value={inputValue}
          InputProps={{
            ...params.InputProps,
            style: {
              height: 44,
              display: 'flex',
              alignItems: 'center',
              paddingTop: 2,
              borderRadius: 8
            },
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          variant="outlined"
          error={error}
        />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings || [];
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props} key={props.id} className={clsx(props.className, classes.optionItem)}>
            {parts.map((part, index) => (
              <span key={index} className={clsx({ [classes.itemBoldText]: part.highlight })}>
                {part.text}
              </span>
            ))}
          </li>
        );
      }}
    />
  );
};

export { AddressAutocomplete };
