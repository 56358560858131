import { Dispatch, SetStateAction } from 'react';

import { ICheckoutCustomer, ICheckoutShipping, IPaymentMethod } from './checkout';
import { IProduct } from './product';

export enum OrderStatusEnum {
  STATUS_DRAFT = '000000000000000000000001',
  STATUS_PENDING = '000000000000000000000002',
  STATUS_PENDING_INFO = '000000000000000000000004',
  STATUS_PENDING_PAYMENT = '000000000000000000000003',
  STATUS_APPROVED = '000000000000000000000005',
  STATUS_PACKED = '000000000000000000000006',
  STATUS_SHIPPED = '000000000000000000000007',
  STATUS_DONE = '000000000000000000000008',
  STATUS_CANCELED = '000000000000000000000009'
}

export enum OrderStatusTextEnum {
  STATUS_DRAFT = 'Очікує підтвердження', // Очікує підтвердження / Нове
  STATUS_PENDING = 'Прийняте до роботи', // Підтвердження / Підтвердження
  STATUS_PENDING_INFO = 'Перевіряється', // Підтвердження / Додаткова інформація
  STATUS_PENDING_PAYMENT = 'Очікує оплати', // Підтвердження / Оплата
  STATUS_APPROVED = 'Підтвержено', // Підтверджено / Підтверджено
  STATUS_PACKED = 'Комплектується', // Скомплектовано / Комплектація
  STATUS_SHIPPED = 'Відправлено', // Доставка / Доставка
  STATUS_DONE = 'Виконане', // Виконано / Виконане
  STATUS_CANCELED = 'Скасоване' // Скасовано / Скасоване
}

// * we show pay button for these statuses
export const paymentButtonStatuses = [
  OrderStatusEnum.STATUS_PENDING_PAYMENT,
  OrderStatusEnum.STATUS_APPROVED,
  OrderStatusEnum.STATUS_PACKED,
  OrderStatusEnum.STATUS_SHIPPED
];

export type IShipmentMethod = 'nova_post' | 'courier';

export interface IShipmentData {
  method: IShipmentMethod;
  address: string;
  TTN?: string | undefined;
}

export interface IOrderDetails {
  customer: ICheckoutCustomer;
  doNotCall: boolean;
  payment?: { method: IPaymentMethod; methodName?: string };
  shipping: ICheckoutShipping;
}

export interface IOrderItem {
  id: string;
  discount: number;
  discountAmount: number;
  discountPrice: number;
  flatProduct: IProduct;
  price: number;
  qty: number;
  special: boolean;
  total: number;
  subTotal: number;
  review?: boolean;
  productOptions?: {
    postcardText?: string;
  };
}

interface ICurrency {
  alias: string;
}

export interface IOrder {
  id: string;
  orderId: string;
  paymentId?: string;
  items: IOrderItem[];
  orderStatus: {
    id: OrderStatusEnum;
    name: string;
  };
  currency: ICurrency | string;
  orderDetails: IOrderDetails;
  shippingAmount: number;
  totalDiscount: number;
  totalQty: number;
  totalSum: number;
  notes?: string;
  allowFeedback?: boolean;
}

export interface IOrdersDataContext {
  orders: IOrder[];
  setOrders: Dispatch<SetStateAction<IOrder[]>>;
  isLoading: boolean;
  loadMoreOrders: (pageSize: number) => void;
  totalDocs?: number;
}

export type IFetchOrders = (params: { query: string; loadMore?: boolean }) => Promise<void>;
