import { FC, useMemo, useState } from 'react';
import clsx from 'clsx';
import { isFinite } from 'lodash';

import { GOOGLE_MAPS_API_KEY } from '@lib/constants/environment';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

import classes from './Map.module.scss';

interface IDeliveryPoint {
  latitude?: string;
  longitude?: string;
  outletId: string;
}

interface IMapProps {
  classNameContainer?: string;
  deliveryPoints?: IDeliveryPoint[];
  handleMarkerOnClick?: (pointId: string) => void;
  activeMarker?: string;
  mapCenter?: { latitude?: string; longitude?: string };
}

const Map: FC<IMapProps> = ({ classNameContainer, deliveryPoints, mapCenter, activeMarker, handleMarkerOnClick }) => {
  const { isLoaded } = useLoadScript({ googleMapsApiKey: GOOGLE_MAPS_API_KEY });
  const [hoveredMarker, setHoveredMarker] = useState(activeMarker || '');

  const handleMarkerMouseOver = (pointId: string) => {
    setHoveredMarker(pointId);
  };

  const handleMarkerMouseOut = () => {
    setHoveredMarker('');
  };

  const handleMarkerClick = (pointId: string) => {
    if (handleMarkerOnClick) {
      if (activeMarker === pointId) {
        handleMarkerOnClick('');
      } else {
        handleMarkerOnClick(pointId);
      }
    }
  };

  const center = useMemo(
    () => ({
      lat: mapCenter && isFinite(mapCenter.latitude) ? Number(mapCenter.latitude) : 50.45010152972752, // Kiev lat
      lng: mapCenter && isFinite(mapCenter.longitude) ? Number(mapCenter.longitude) : 30.523393894080574 // Kiev lng
    }),
    [mapCenter]
  );

  if (!isLoaded) return <div className={classes.loadingContainer}>Завантаження...</div>;

  return (
    <GoogleMap zoom={11} center={center} mapContainerClassName={clsx(classes.mapContainer, classNameContainer)}>
      {deliveryPoints?.length
        ? deliveryPoints.map((point) => (
            <Marker
              key={point ? point.outletId : undefined}
              position={{ lat: Number(point.latitude), lng: Number(point.longitude) }}
              icon={
                activeMarker === (point ? point.outletId || hoveredMarker === point.outletId : undefined)
                  ? '/assets/images/geo-location-point-active.svg'
                  : '/assets/images/geo-location-point.svg'
              }
              onMouseOver={() => handleMarkerMouseOver(point.outletId)}
              onMouseOut={handleMarkerMouseOut}
              onClick={() => handleMarkerClick(point.outletId)}
            />
          ))
        : null}
    </GoogleMap>
  );
};

export { Map, type IDeliveryPoint };
