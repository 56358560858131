import { FC, MouseEvent } from 'react';

import { Icon } from '../../shared';

import classes from '../Search.module.scss';

interface IProps {
  label: string;
  onSelect: (label: string) => void;
  onRemove: (label: string) => (e: MouseEvent<HTMLDivElement>) => void;
}

const SearchHistoryOption: FC<IProps> = ({ label, onSelect, onRemove }) => {
  return (
    <span onClick={() => onSelect(label)} className={classes.searchHistoryItem}>
      {label}
      <Icon onClick={onRemove(label)} type="cancel" size={16} leftOffset={4} />
    </span>
  );
};

export { SearchHistoryOption };
