import { FC, ReactNode } from 'react';
import classes from './ProductCardIcons.module.scss';

interface IProps {
  children: ReactNode;
}
const ProductCardIcons: FC<IProps> = ({ children }) => {
  return <div className={classes.container}>{children}</div>;
};

export { ProductCardIcons };
