/* eslint-disable @next/next/no-img-element */
'use client';

import { ChangeEvent, FC, MouseEvent, useRef, useState } from 'react';
import clsx from 'clsx';

import { FormError, Icon } from '@components';

import classes from './ImageUpload.module.scss';

interface IImageUploadProps {
  onUpload: (files: File[]) => void;
}

const MAX_FILE_SIZE_MB = 5;

const ImageUpload: FC<IImageUploadProps> = ({ onUpload }) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  function readFile(file: File): File {
    const reader: FileReader = new FileReader();
    reader.addEventListener('load', (event: ProgressEvent<FileReader>) => event.target?.result);

    /* this block need to calculate percent

    reader.addEventListener('progress', (event: ProgressEvent<FileReader>) => {
      if (event.loaded && event.total) {
        const percent: number = (event.loaded / event.total) * 100;
        console.log(`Progress: ${Math.round(percent)}`);
      }
    });

    */
    reader.readAsDataURL(file);
    return file;
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');

    const fileList = event.target.files;
    if (fileList) {
      const filesArray = Array.from(fileList);
      if (selectedFiles.length + filesArray.length > 5) {
        setErrorMessage('Можна вибрати максимум 5 зображень');
        return;
      }

      const newFiles = filesArray.map((file) => {
        const fileSize = file.size;
        const validFile = fileSize <= MAX_FILE_SIZE_MB * 1024 * 1024;

        if (validFile) {
          return readFile(file);
        } else {
          setErrorMessage('Фото завелике за розміром');
          return null;
        }
      });

      const validFiles = newFiles.filter((file) => file !== null) as File[];

      if (validFiles.length > 0) {
        setSelectedFiles((prevFiles) => {
          const updatedFiles = [...prevFiles, ...validFiles];
          onUpload(updatedFiles);
          return updatedFiles;
        });
      } else {
        setErrorMessage('Виберіть фотографії для завантаження');
      }
    }
  };

  const handleRemoveFile = (fileToRemove: File, e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const filteredFiles = selectedFiles.filter((file) => file !== fileToRemove);
    setSelectedFiles(filteredFiles);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const openFileDialog = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <p className={classes.description}>Формат файлів JPG, JPEG, PNG. Максимальний розмір файлу 5МВ.</p>
      <div className={clsx({ [classes.container]: selectedFiles.length > 0 })}>
        <input
          ref={fileInputRef}
          type="file"
          accept=".jpg, .jpeg, .png, .webp"
          multiple
          onChange={handleFileChange}
          className={classes.inputField}
        />
        <button
          onClick={openFileDialog}
          className={clsx(classes.chooseFiles, { [classes.addMoreImages]: selectedFiles.length > 0 })}
          aria-label="Додати фото"
        >
          <Icon type="photocamera" size={30} />
          Додати фото
        </button>
        {selectedFiles.map((file, index) => (
          <div key={index} className={classes.imageContainer}>
            <img src={URL.createObjectURL(file)} alt={`Preview ${index}`} className={classes.image} />
            <button onClick={(e) => handleRemoveFile(file, e)} className={classes.deleteBtn} aria-label="Видалити">
              <Icon type="deleteBtn" size={16} className={classes.icon} />
            </button>
          </div>
        ))}
      </div>
      <FormError message={errorMessage} />
    </>
  );
};

export default ImageUpload;
