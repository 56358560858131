import { API_URL } from '@lib/constants/environment';
import { IPhoneConfirmationResponse } from '@types';
import { handleError } from '@utils/error-handling';

export async function postSendCode(phone: string): Promise<IPhoneConfirmationResponse> {
  const res = await fetch(`${API_URL}/auth/verification/phone/send`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ phone }),
    cache: 'no-cache'
  });
  return handleError<IPhoneConfirmationResponse>(res);
}

export async function postVerifyCode(phone: string, verificationCode: string): Promise<IPhoneConfirmationResponse> {
  const res = await fetch(`${API_URL}/auth/verification/phone/verify`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ phone, verificationCode }),
    cache: 'no-cache'
  });
  return handleError<IPhoneConfirmationResponse>(res);
}
