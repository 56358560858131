import { ButtonHTMLAttributes, FC, MouseEventHandler, ReactNode } from 'react';
import clsx from 'clsx';

import { Icon } from '../../icon/Icon';
import { ICONS } from '../../icon/icons';

import classes from './IconButton.module.scss';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  ariaLabel: string;
  children?: ReactNode | string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  icon?: keyof typeof ICONS;
  iconSize?: number;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}

const IconButton: FC<IProps> = ({ children, onClick, className, disabled, icon, iconSize, type, ariaLabel }) => {
  return (
    <button
      onClick={onClick}
      className={clsx(classes.button, className)}
      type={type}
      disabled={disabled}
      aria-label={ariaLabel}
    >
      {icon ? <Icon type={icon} size={iconSize} /> : children}
    </button>
  );
};

export { IconButton };
