import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { postForgotPassword } from '@api/auth';
import { ButtonFPot, FormError, Input, PhoneInput } from '@components';
import { ERROR_MESSAGES } from '@lib/error-messages';
import { IRemindPasswordFormProps, IRemindPasswordFormValues } from '@types';
import { validatePhoneNumber } from '@utils/formatting';

import classes from './RemindPasswordForm.module.scss';

const RemindPasswordForm: FC<IRemindPasswordFormProps> = ({ setResetPasswordToken, activeTab, setAuthId }) => {
  const [pending, setPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { handleSubmit, control } = useForm<IRemindPasswordFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      phone: '',
      email: ''
    }
  });

  function handleRemindPassword(formData: IRemindPasswordFormValues) {
    setErrorMessage('');
    setPending(true);
    postForgotPassword({ authId: activeTab === 0 ? formData.phone : formData.email })
      .then((data) => {
        if (data?._sentData?.token) {
          setAuthId(activeTab === 0 ? formData.phone : formData.email);
          setResetPasswordToken(data._sentData.token);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message || ERROR_MESSAGES.incorrectCode);
      })
      .finally(() => setPending(false));
  }

  return (
    <form onSubmit={handleSubmit(handleRemindPassword)}>
      {activeTab === 0 ? (
        <Controller
          render={({ field: { ref: _, ...restField }, fieldState }) => (
            <PhoneInput
              {...restField}
              valid={!fieldState.invalid}
              onEnterKeyPress={handleSubmit(handleRemindPassword)}
              classNameWrapper={classes.input}
            />
          )}
          name="phone"
          control={control}
          rules={{
            required: true,
            validate: validatePhoneNumber
          }}
        />
      ) : null}
      {activeTab === 1 ? (
        <Controller
          render={({ field: { value, onChange }, fieldState }) => (
            <Input
              label="Email"
              id="email"
              error={fieldState.invalid}
              errorMessage="Необхідно ввести email"
              value={value}
              onChange={onChange}
              classNameWrapper={classes.input}
            />
          )}
          name="email"
          control={control}
          rules={{ required: true }}
        />
      ) : null}
      <FormError message={errorMessage} />
      <ButtonFPot disabled={pending} label="Відправити пароль" type="submit" fullWidth />
    </form>
  );
};
export { RemindPasswordForm };
