import { FC } from 'react';
import clsx from 'clsx';

import { UAH } from '@lib/constants/common-constants';
import { changeWordEnding, formatNumber, roundAndFormatNumber } from '@utils/formatting';

import classes from './OrderSummary.module.scss';

interface IProps {
  qty?: number;
  totalQty?: number | string;
  discount?: number | string;
  shippingPrice?: number | string;
  total?: number | string;
}

export const OrderSummary: FC<IProps> = ({ discount, shippingPrice, total, qty, totalQty }) => (
  <>
    {qty && totalQty ? (
      <div className={classes.item}>
        <span className={classes.label}>{changeWordEnding(qty, 'товар')} на суму:</span>
        <span>
          {formatNumber(totalQty)}&nbsp;{UAH}
        </span>
      </div>
    ) : null}
    {discount ? (
      <div className={classes.item}>
        <span className={classes.label}>Знижка:</span>
        <span className={classes.discount}>
          -{roundAndFormatNumber(discount)}&nbsp;{UAH}
        </span>
      </div>
    ) : null}
    {shippingPrice ? (
      <div className={classes.item}>
        <span className={classes.label}>Доставка:</span>
        <span>
          {formatNumber(shippingPrice)}&nbsp;{UAH}
        </span>
      </div>
    ) : null}
    {total ? (
      <div className={clsx(classes.item, classes.itemTotal)}>
        <span className={classes.label}>Разом:</span>
        <span className={classes.total}>
          {roundAndFormatNumber(total)}&nbsp;{UAH}
        </span>
      </div>
    ) : null}
  </>
);
