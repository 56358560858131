import { FC, useEffect, useRef } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';

import { ISystemOutlet } from '@types';

import classes from './ShopsDesktop.module.scss';

interface IShopsDesktopProps {
  handleMarkerOnClick: (pointId: string) => void;
  selectedCityOutlets?: ISystemOutlet[];
  selectedShop?: string;
}

const ShopsDesktop: FC<IShopsDesktopProps> = ({ selectedCityOutlets, handleMarkerOnClick, selectedShop }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      const currentOutletIndex = selectedCityOutlets?.findIndex((o) => o.outletId === selectedShop);
      if (currentOutletIndex !== undefined && currentOutletIndex !== -1) {
        containerRef.current?.children[currentOutletIndex].scrollIntoView(false);
      }
    }
  }, [selectedShop]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.selectedCity} ref={containerRef}>
        {selectedCityOutlets &&
          selectedCityOutlets.map((params) => {
            const image = params.image
              ? typeof params.image === 'string'
                ? params.image
                : params.image.url
              : params.gallery && (typeof params.gallery[0] === 'string' ? params.gallery[0] : params.gallery[0]?.url);
            const workingHours = params.workingHours
              ? `Пн-Нд: ${params?.workingHours?.start} - ${params?.workingHours?.end}`
              : '';
            return (
              <div
                key={params.outletId}
                className={clsx(classes.wrapperSelectedCity, {
                  [classes.active]: params.outletId === selectedShop
                })}
                onClick={() => handleMarkerOnClick(params.outletId)}
              >
                <Image
                  src={image || '/assets/images/flower-no-image.svg'}
                  alt={params.address}
                  className={classes.img}
                  quality={100}
                  width={380}
                  height={200}
                />
                <p className={classes.typeBuilding}>{params.name}</p>
                <p className={classes.street}>{params.address}</p>
                <div className={classes.infoWrapper}>
                  <a href={`tel:+${params.phone}`} className={classes.phone}>
                    +{params.phone}
                  </a>
                  <div className={classes.workHours}>{workingHours}</div>
                </div>
                <Link
                  href={
                    params.googleMapLink
                      ? params.googleMapLink
                      : `https://www.google.com/maps/search/?api=1&query=${params.latitude},${params.longitude}`
                  }
                  className={classes.link}
                  target="_blank"
                >
                  Прокласти маршрут
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export { ShopsDesktop };
