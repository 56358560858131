import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useRouter } from 'next/navigation';
import { signIn } from 'next-auth/react';

import { ButtonFPot, FormError, OtpInput, UnderlinedButton } from '@components';
import { ERROR_MESSAGES } from '@lib/error-messages';
import { IVerifyCodeFormValues } from '@types';
import { useConfirmPhone } from '../confirm-phone-provider';

import classes from './VerifyCodeForm.module.scss';

interface IProps {
  closeModal: () => void;
  registrationPassword?: string;
}

const VerifyCodeForm: FC<IProps> = ({ closeModal, registrationPassword }) => {
  const router = useRouter();
  const { pending, phone, verifyCode, setPhoneEditing, timeLeft, sendCode, errorMessage, setErrorMessage } =
    useConfirmPhone();
  const { handleSubmit, control, setError } = useForm<IVerifyCodeFormValues>({
    mode: 'onSubmit',
    defaultValues: { code: '' },
    shouldUnregister: true
  });

  const handleResendCode = () => {
    sendCode(phone);
  };

  const handleEdit = () => {
    setPhoneEditing(true);
  };

  const onVerifyCode = ({ code }: IVerifyCodeFormValues) => {
    verifyCode(phone, code).then((data) => {
      if (data?.success) {
        signIn('credentials', {
          password: registrationPassword,
          remember: false,
          authId: phone,
          redirect: false
        })
          .then((callback) => {
            if (callback?.error) {
              setErrorMessage(ERROR_MESSAGES.commonError);
            }

            if (callback?.ok && !callback?.error) {
              if (closeModal) {
                closeModal();
              }

              if (callback.url) {
                const params = new URLSearchParams(new URL(callback.url).search);
                const redirectUrl = params.get('callbackUrl');
                if (redirectUrl) {
                  router.replace(redirectUrl);
                }
              }
            }
          })
          .catch((error) => {
            setErrorMessage(error.message);
          });
      } else {
        setError('code', { message: 'Невірний код', type: 'validate' });
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onVerifyCode)}>
      <h3 className={classes.title}>Введіть код з SMS</h3>
      <div className={classes.checkInPassword}>
        <p className={classes.description}>Ми відправили смс-код на номер</p>
        <div className={classes.correctPhoneNumber}>
          <span className={classes.phone}>+{phone}</span>
          <UnderlinedButton
            className={classes.editPhoneBtn}
            onClick={handleEdit}
            disabled={pending}
            label="Редагувати"
            type="button"
          />
        </div>
      </div>
      <Controller
        render={({ field: { ref: _, ...restField }, fieldState }) => (
          <OtpInput {...restField} valid={!fieldState.invalid} disabled={pending} />
        )}
        name="code"
        control={control}
        rules={{
          required: true,
          minLength: 6
        }}
      />
      <FormError message={errorMessage} />
      <ButtonFPot classNameWrapper={classes.verifyBtn} label="Підтвердити" disabled={pending} type="submit" fullWidth />
      {timeLeft > 0 ? (
        <p className={classes.timeLeft}>Відправити код повторно можна через {timeLeft}с</p>
      ) : (
        <UnderlinedButton
          className={classes.resendBtn}
          onClick={handleResendCode}
          disabled={pending}
          label="Відправити код повторно"
        />
      )}
    </form>
  );
};
export { VerifyCodeForm };
