import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSession } from 'next-auth/react';

import { ButtonFPot, FormError, Input, PhoneInput, TextArea } from '@components';
import ImageUpload from '@components/shared/image-upload/ImageUpload';
import StarRate from '@components/shared/star-rate/StarRate';
import { IFeedbackFormValues, IFeedbackPayload } from '@types';
import { validatePhoneNumber } from '@utils/formatting';

import classes from './FeedbackForm.module.scss';

interface IFeedbackFormProps {
  onSubmit: (formValues: IFeedbackPayload) => void;
  errorMessage?: string;
  disabledButton?: boolean;
}

const FeedbackForm: FC<IFeedbackFormProps> = ({ onSubmit, errorMessage, disabledButton }) => {
  const { data: session } = useSession();
  const firstName = session?.user?.profile?.firstName;
  const phone = session?.user?.phone;
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const { handleSubmit, control, setValue } = useForm<IFeedbackFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      name: firstName ?? '',
      phone: phone ?? '',
      review: '',
      rating: 0
    }
  });

  useEffect(() => {
    if (session?.user?.profile) {
      setValue('name', session.user.profile.firstName || '');
    }
    if (session?.user?.phone) {
      setValue('phone', session.user.phone || '');
    }
  }, [session?.user]);

  const handleFormSubmit = (data: IFeedbackFormValues) => {
    onSubmit({ ...data, images: selectedFiles });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Controller
        render={({ field: { value, onChange } }) => <StarRate currentRate={value} setRate={onChange} />}
        name="rating"
        control={control}
      />
      <Controller
        render={({ field: { value, onChange }, fieldState }) => (
          <Input
            label="Ім'я"
            id="name"
            error={fieldState.invalid}
            errorMessage="Необхідно ввести ім'я"
            value={value}
            onChange={onChange}
          />
        )}
        name="name"
        control={control}
        rules={{ required: true }}
      />
      <Controller
        render={({ field: { ref: _, ...restField }, fieldState }) => (
          <PhoneInput {...restField} valid={!fieldState.invalid} />
        )}
        name="phone"
        control={control}
        rules={{
          required: true,
          validate: validatePhoneNumber
        }}
      />
      <Controller
        render={({ field: { onChange, value } }) => (
          <TextArea
            label="Текст відгука"
            onChange={onChange}
            value={value}
            id="review"
            classNameWrapper={classes.textArea}
          />
        )}
        rules={{ required: true }}
        name="review"
        control={control}
      />
      <ImageUpload onUpload={setSelectedFiles} />
      <FormError message={errorMessage} />
      <ButtonFPot label="Відправити" type="submit" fullWidth disabled={disabledButton}/>
    </form>
  );
};

export default FeedbackForm;
