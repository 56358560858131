import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { postResetPassword } from '@api/auth';
import { ButtonFPot, FormError, InputPassword } from '@components/shared';
import { ERROR_MESSAGES } from '@lib/error-messages';
import { INewPasswordFormProps, INewPasswordFormValues } from '@types';

import classes from './NewPasswordForm.module.scss';

const NewPasswordForm: FC<INewPasswordFormProps> = ({ authId, closeModal, resetPasswordToken, resetKey }) => {
  const [pending, setPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { handleSubmit, control } = useForm<INewPasswordFormValues>({
    mode: 'onSubmit',
    defaultValues: { password: '' },
    shouldUnregister: true
  });

  function onSaveNewPassword(formData: INewPasswordFormValues) {
    setErrorMessage('');
    setPending(true);
    postResetPassword({ authId, password: formData.password, token: resetPasswordToken, resetKey })
      .then(() => {
        closeModal();
      })
      .catch((error) => {
        setErrorMessage(error?.message || ERROR_MESSAGES.commonError);
      })
      .finally(() => setPending(false));
  }

  return (
    <form onSubmit={handleSubmit(onSaveNewPassword)}>
      <Controller
        render={({ field: { value, onChange }, fieldState }) => (
          <InputPassword
            label="Пароль"
            id="password"
            value={value}
            onChange={onChange}
            error={fieldState.invalid}
            errorMessage="Пароль має містити принаймні 8 символів"
            classNameWrapper={classes.input}
          />
        )}
        name="password"
        control={control}
        rules={{ required: true }}
      />
      <FormError message={errorMessage} />
      <ButtonFPot label="Зберегти" disabled={pending} type="submit" fullWidth />
    </form>
  );
};
export { NewPasswordForm };
