import { FC, KeyboardEvent } from 'react';
import ReactPhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';

import clsx from 'clsx';

import classes from './PhoneInput.module.scss';

interface IPhoneNumberInput {
  onChange: (value: string) => void;
  disabled?: boolean;
  value?: string;
  valid?: boolean;
  onBlur?: () => void;
  name?: string;
  onEnterKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
  classNameWrapper?: string;
}

const PhoneInput: FC<IPhoneNumberInput> = ({
  onChange,
  disabled,
  value,
  valid,
  onBlur,
  name,
  onEnterKeyPress,
  classNameWrapper
}) => (
  <div className={clsx(classes.wrapper, classNameWrapper)}>
    <ReactPhoneInput
      country={'ua'}
      countryCodeEditable={false}
      inputClass={clsx(classes.input, { [classes.error]: !valid })}
      containerClass={clsx(classes.container, {
        [classes.containerError]: !valid,
        [classes.containerDisabled]: disabled
      })}
      excludeCountries={['ru']}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      inputProps={{ name }}
      onEnterKeyPress={onEnterKeyPress}
      isValid={valid}
      disabled={disabled}
    />
    {!valid && <p style={{ color: 'red', marginTop: 5 }}>Введіть правильний номер телефону.</p>}
  </div>
);

export { PhoneInput };
