import { API_URL, PRODUCTION_ENV } from '@lib/constants/environment';
import { ICheckoutData, IFinalizeCheckoutResponse, IOrder, IQuickBuyPayload } from '@types';
import { handleError } from '@utils/error-handling';
import { retrieveGACookie } from '@utils/ga-cookies';

export async function postCheckout(data: Partial<ICheckoutData>): Promise<ICheckoutData[]> {
  const res = await fetch(`${API_URL}/sales/checkout`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
    cache: 'no-cache'
  });
  return handleError<ICheckoutData[]>(res, { body: data });
}

export async function postCheckoutFinalize(data: Partial<ICheckoutData>): Promise<IFinalizeCheckoutResponse> {
  const res = await fetch(`${API_URL}/sales/checkout/finalize`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(PRODUCTION_ENV ? { ...data, gaAnalytics: retrieveGACookie() } : data),
    cache: 'no-cache'
  });
  return handleError<IFinalizeCheckoutResponse>(res);
}

export async function postQuickBuy(data: IQuickBuyPayload): Promise<IOrder> {
  const res = await fetch(`${API_URL}/sales/checkout/quick-order`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(PRODUCTION_ENV ? { ...data, gaAnalytics: retrieveGACookie() } : data),
    cache: 'no-cache'
  });
  return handleError<IOrder>(res);
}

export async function getOrderPreviewData(orderToken: string): Promise<IOrder> {
  const res = await fetch(`${API_URL}/sales/checkout/order-preview/${orderToken}`, {
    headers: {
      'Content-Type': 'application/json'
    },
    cache: 'no-cache'
  });
  return handleError<IOrder>(res);
}

export async function getPaymentRequest(paymentId: string): Promise<IFinalizeCheckoutResponse> {
  const res = await fetch(`${API_URL}/sales/payment-request/${paymentId}`, {
    headers: {
      'Content-Type': 'application/json'
    },
    cache: 'no-cache'
  });
  return handleError<IFinalizeCheckoutResponse>(res);
}
