import { FC, useMemo } from 'react';
import Link from 'next/link';
import clsx from 'clsx';

import { Icon } from '@components';

import classes from './PaginationList.module.scss';

interface PaginationListProps {
  currentPage: number;
  totalPages: number;
  page: number;
  generateLink: (pageNumber: number | null) => string;
}

const PaginationList: FC<PaginationListProps> = ({ currentPage, totalPages, page, generateLink }) => {
  const generatePageRange = useMemo(() => {
    let range = [];

    if (totalPages <= 7) {
      // Show all pages if total pages are less than or equal to 7
      for (let i = 1; i <= totalPages; i++) {
        range.push(i);
      }
    } else {
      if (page <= 3) {
        // Near the start: show first 4 pages
        for (let i = 1; i <= 4; i++) {
          range.push(i);
        }
        range.push(0); // 0 represents '...'
        range.push(totalPages);
      } else if (page >= totalPages - 2) {
        // Near the end: show first page, '...', then last 4 pages
        range.push(1);
        range.push(0); // 0 represents '...'
        for (let i = totalPages - 3; i <= totalPages; i++) {
          range.push(i);
        }
      } else {
        // Middle pages: show current page ±1
        range = [1, 0, page - 1, page, page + 1, 0, totalPages];
      }
    }

    return range;
  }, [page, totalPages]);

  const hasPreviousPage = currentPage > 1;
  const hasNextPage = currentPage < totalPages;

  return (
    <nav className={classes.paginationWrapper} aria-label="Навігація по сторінкам">
      <ul className={classes.paginationList}>
        <li className={clsx(classes.paginationItem, classes.arrow, { [classes.disabled]: !hasPreviousPage })}>
          <Link href={generateLink(currentPage - 1)} aria-label="Попередня сторінка">
            <Icon
              size={24}
              type="arrowForward"
              style={{ transform: 'rotate(180deg)' }}
              className={clsx({ [classes.disabledArrow]: !hasPreviousPage })}
            />
          </Link>
        </li>
        {generatePageRange.map((pageItem, index) => (
          <li key={index} className={clsx({ [classes.active]: currentPage === pageItem })}>
            {pageItem !== 0 ? (
              <Link
                href={generateLink(pageItem)}
                aria-label={`Перейти на сторінку ${pageItem}`}
                className={classes.paginationItem}
              >
                {pageItem}
              </Link>
            ) : (
              <span className={classes.paginationEllipsis}>...</span>
            )}
          </li>
        ))}
        <li className={clsx(classes.paginationItem, classes.arrow, { [classes.disabled]: !hasNextPage })}>
          <Link href={generateLink(currentPage + 1)} aria-label="Наступна сторінка">
            <Icon size={24} type="arrowForward" className={clsx({ [classes.disabledArrow]: !hasNextPage })} />
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default PaginationList;
