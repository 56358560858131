import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import classes from './ContentCard.module.scss';

interface IProps {
  children: ReactNode;
  className?: string;
}

export const ContentCard: FC<IProps> = ({ children, className }) => {
  return <div className={clsx(classes.card, className)}>{children}</div>;
};
