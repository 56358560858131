import Cookies from 'js-cookie';

function getClientId(gaCookie: string | undefined) {
  if (gaCookie) {
    const clientId = gaCookie.split('.').slice(-2).join('.');
    return clientId;
  }
  return '';
}

export function retrieveGACookie() {
  const payload = {
    utm_source: Cookies.get('utm_source'),
    utm_medium: Cookies.get('utm_medium'),
    utm_campaign: Cookies.get('utm_campaign'),
    utm_content: Cookies.get('utm_content'),
    utm_term: Cookies.get('utm_term'),
    gclid: Cookies.get('gclid'),
    client_id: getClientId(Cookies.get('_ga')), // need to split
    session_id: Cookies.get('_ga_1NL6DK0ZF0'),
    user_agent: window?.navigator?.userAgent
  };

  return payload;
}
