import { FC, memo, MouseEvent, SyntheticEvent, useEffect, useState } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import clsx from 'clsx';

import { filterStringToJson } from '@page-layouts/catalog/FiltersBar/utils';
import { Icon, IconButton } from '../shared';
import PaginationList from './pagination-list/PaginationList';

import classes from './Pagination.module.scss';

interface IProps {
  loadMoreTitle: string;
  totalPages: number;
  disabled?: boolean;
  onLoadMore?: (count: number) => void;
  className?: string;
}

const Pagination: FC<IProps> = ({ loadMoreTitle, onLoadMore, totalPages, disabled, className }) => {
  const pathName = usePathname();
  const [loadMoreCount, setLoadMoreCount] = useState(0);
  const [page, setPage] = useState(1);

  const searchParams = useSearchParams();
  const searchParamsStringified = searchParams.toString();

  useEffect(() => {
    const filterPrams = filterStringToJson(decodeURIComponent(searchParamsStringified), undefined);
    if (filterPrams.pageNumber) {
      setPage(Number(filterPrams.pageNumber) || 1);
    } else if (!filterPrams.pageNumber && page !== 1) {
      setPage(1);
    }
  }, [searchParams]);

  const onShowMore = (_e: SyntheticEvent | MouseEvent) => {
    if (onLoadMore) {
      const params = new URLSearchParams(searchParamsStringified);
      const newPage = page + loadMoreCount + 1;
      params.set('pageNumber', `${newPage}`);
      setPage(newPage);
      setLoadMoreCount(0);

      const url = params.toString();
      onLoadMore(newPage);
      // ! TODO workaround for nextjs app router (still in progress, ETA end of the 2023)
      // https://github.com/vercel/next.js/discussions/48110
      history.replaceState(null, '', `${pathName}?${decodeURIComponent(url)}`);
    }
  };

  const generateLink = (pageNumber: number | null) => {
    if (!pageNumber) return `${pathName}?${searchParams.toString()}`;
    const params = new URLSearchParams(searchParamsStringified);
    params.set('pageNumber', `${loadMoreCount > 0 ? page + loadMoreCount + 1 : pageNumber}`);
    const url = params.toString();
    return `${pathName}?${decodeURIComponent(url)}`;
  };

  const isLoadMoreDisabled = disabled || loadMoreCount + 1 >= totalPages || page === totalPages;
  return (
    <div className={clsx(classes.paginationContainer, className)}>
      {totalPages > 1 ? (
        <IconButton
          className={classes.showMoreBtn}
          onClick={onShowMore}
          disabled={isLoadMoreDisabled}
          ariaLabel="Завантажити більше"
        >
          <Icon
            type="return"
            size={16}
            offset={10}
            className={clsx(classes.showMoreIcon, { [classes.showMoreIconDisabled]: isLoadMoreDisabled })}
          />
          <span>{loadMoreTitle}</span>
        </IconButton>
      ) : null}
      {totalPages > 1 && (
        <PaginationList totalPages={totalPages} currentPage={page} page={page} generateLink={generateLink} />
      )}
    </div>
  );
};

const areEqual = (prev: IProps, next: IProps) => prev.totalPages === next.totalPages && prev.disabled === next.disabled;
const PaginationMemo = memo(Pagination, areEqual);

export { PaginationMemo as Pagination };
