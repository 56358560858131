import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { postForgotPassword, postValidateResetKeyPassword } from '@api/auth';
import { ButtonFPot, FormError, OtpInput, UnderlinedButton } from '@components/shared';
import { ERROR_MESSAGES } from '@lib/error-messages';
import { IConfirmPasswordFormProps, IConfirmPasswordFormValues } from '@types';

import classes from './ConfirmPasswordForm.module.scss';

const ConfirmPasswordForm: FC<IConfirmPasswordFormProps> = ({
  title,
  authId,
  token,
  setResetPasswordToken,
  onSuccessVerify,
  setResetKey
}) => {
  const [pending, setPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { handleSubmit, control } = useForm<IConfirmPasswordFormValues>({
    mode: 'onSubmit',
    defaultValues: { resetKey: '' },
    shouldUnregister: true
  });

  const handleResendCode = () => {
    setErrorMessage('');
    setPending(true);
    postForgotPassword({ authId })
      .then((data) => {
        if (data?._sentData?.token) {
          setResetPasswordToken(data._sentData.token);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message || ERROR_MESSAGES.commonError);
      })
      .finally(() => setPending(false));
  };

  function onVerifyCode({ resetKey }: IConfirmPasswordFormValues) {
    setErrorMessage('');
    setPending(true);
    setResetKey(resetKey);
    postValidateResetKeyPassword({ resetKey, authId, token })
      .then((data) => {
        if (data?.isValid) {
          onSuccessVerify();
        }
      })
      .catch((error) => {
        setErrorMessage(error.message || ERROR_MESSAGES.commonError);
      })
      .finally(() => setPending(false));
  }

  return (
    <form onSubmit={handleSubmit(onVerifyCode)}>
      <p className={classes.title}>{title}</p>
      <p className={classes.value}>{authId}</p>
      <Controller
        render={({ field: { ref: _, ...restField }, fieldState }) => (
          <OtpInput {...restField} valid={!fieldState.invalid} disabled={pending} />
        )}
        name="resetKey"
        control={control}
        rules={{
          required: true,
          minLength: 6
        }}
      />
      <FormError message={errorMessage} />
      <UnderlinedButton
        className={classes.resendBtn}
        onClick={handleResendCode}
        disabled={pending}
        label="Відправити код повторно"
      />
      <ButtonFPot classNameWrapper={classes.submitBtn} label="Далі" disabled={pending} type="submit" fullWidth />
    </form>
  );
};
export { ConfirmPasswordForm };
