import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { postRegister } from '@api/auth';
import { ButtonFPot, FormError, Input, InputPassword, PhoneInput, UnderlinedButton } from '@components';
import { IRegistrationFormProps, IRegistrationFormValues } from '@types';
import { validatePhoneNumber } from '@utils/formatting';

import classes from './RegistrationForm.module.scss';

const RegistrationForm: FC<IRegistrationFormProps> = ({ handleContinue, handleOpenAuthorization }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [pending, setPending] = useState(false);
  const { handleSubmit, control, watch } = useForm<IRegistrationFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      phone: ''
    }
  });

  useEffect(() => {
    const subscription = watch((_value, { type }) => {
      if (type === 'change' && errorMessage) {
        setErrorMessage('');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, errorMessage]);

  const handleRegister = async (formData: IRegistrationFormValues) => {
    setErrorMessage('');
    setPending(true);

    postRegister({ ...formData, confirmPassword: formData.password })
      .then(({ user }) => {
        if (user && user.phone) {
          handleContinue(user.phone, formData.password);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(handleRegister)}>
      <h2 className={classes.registerTitle}>Реєстрація</h2>
      <Controller
        render={({ field: { value, onChange }, fieldState }) => (
          <Input
            label="Ім'я"
            id="firstName"
            error={fieldState.invalid}
            errorMessage="Необхідно ввести ім'я"
            value={value}
            onChange={onChange}
          />
        )}
        name="firstName"
        control={control}
        rules={{ required: true }}
      />
      <Controller
        render={({ field: { value, onChange }, fieldState }) => (
          <Input
            label="Прізвище"
            id="lastName"
            error={fieldState.invalid}
            errorMessage="Необхідно ввести прізвище"
            value={value}
            onChange={onChange}
          />
        )}
        name="lastName"
        control={control}
        rules={{ required: true }}
      />
      <Controller
        render={({ field: { ref: _, ...restField }, fieldState }) => (
          <PhoneInput {...restField} valid={!fieldState.invalid} onEnterKeyPress={handleSubmit(handleRegister)} />
        )}
        name="phone"
        control={control}
        rules={{
          required: true,
          validate: validatePhoneNumber
        }}
      />
      <Controller
        render={({ field: { value, onChange }, fieldState }) => (
          <Input
            label="Email"
            id="email"
            error={fieldState.invalid}
            errorMessage="Необхідно ввести email"
            value={value}
            onChange={onChange}
          />
        )}
        name="email"
        control={control}
      />
      <Controller
        render={({ field: { value, onChange }, fieldState }) => (
          <InputPassword
            label="Пароль"
            id="password"
            error={fieldState.invalid}
            value={value}
            onChange={onChange}
            errorMessage="Пароль має містити принаймні 8 символів"
          />
        )}
        name="password"
        control={control}
        rules={{ required: true }}
      />
      <FormError message={errorMessage} />
      <ButtonFPot label="Зареєструватись" disabled={pending} type="submit" spinner={pending} fullWidth />
      <label className={classes.subscription}>
        <input type="checkbox" className={classes.checkBox} />
        Підписатись на розсилку. Обіцяємо не спамити
      </label>
      <div className={classes.signIn}>
        <p className={classes.signInTitle}>Вже зареєстровані?</p>
        <UnderlinedButton
          className={classes.signInLink}
          onClick={handleOpenAuthorization}
          disabled={pending}
          label="Увійдіть"
        />
      </div>
    </form>
  );
};

export { RegistrationForm };
