'use client';

import { ChangeEvent, useEffect, useRef, useState } from 'react';
import _throttle from 'lodash/throttle';

import { ISearchHistory, ISearchResults } from '@types';
import { getSearchApi } from '../../api/search';

const useSearchControl = () => {
  const [searchHistory, setSearchHistory] = useState<ISearchHistory[] | []>(
    JSON.parse(localStorage.getItem('searchHistory') || '[]')
  );
  const [searchPending, setSearchPending] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<ISearchResults | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  // * abort search request if user close search window
  useEffect(
    () => () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    },
    []
  );

  const throttledRequest = useRef(
    _throttle(
      (q: string) => {
        setSearchPending(true);
        const abortController = new AbortController();
        abortControllerRef.current = abortController;

        getSearchApi(q, abortController.signal)
          .then(({ results }) => {
            if (results && (results.products || results.catalogCategory)) {
              const products = results.products.slice(0, 6);
              const catalogCategory = results.catalogCategory.slice(0, 3);
              setSearchResults({ products, catalogCategory, total: results.total });
            }
          })
          .catch((e: Error) => {
            console.log(e);
          })
          .finally(() => {
            setSearchPending(false);
          });
      },
      500,
      { leading: false, trailing: true }
    )
  );

  useEffect(() => {
    if (!searchQuery || searchQuery.length < 3) return;

    throttledRequest.current(
      typeof searchQuery.replaceAll === 'function' ? searchQuery.replaceAll('#', '') : searchQuery
    );
  }, [searchQuery]);

  const updateSelectionHistory = (selectedItem: ISearchHistory) => {
    if (searchHistory.find((i) => selectedItem.label === i.label)) return;

    const newSearchHistory = [selectedItem, ...searchHistory];
    if (searchHistory.length > 2) {
      newSearchHistory.length = 3;
    }

    localStorage.setItem('searchHistory', JSON.stringify(newSearchHistory));
    setSearchHistory(newSearchHistory);
  };

  const removeFromHistory = (label: string) => {
    const newSearchHistory = searchHistory.filter((i) => label !== i.label);
    localStorage.setItem('searchHistory', JSON.stringify(newSearchHistory));
    setSearchHistory(newSearchHistory);
  };

  const clearSearchHistory = () => {
    setSearchHistory([]);
    localStorage.removeItem('searchHistory');
  };

  const onSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return {
    searchResults,
    searchPending,
    setSearchQuery,
    clearSearchHistory,
    searchHistory,
    onSearchInputChange,
    searchQuery,
    updateSelectionHistory,
    removeFromHistory
  };
};

export { useSearchControl };
