import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSession } from 'next-auth/react';
import { v4 as uuid } from 'uuid';

import { postQuickBuy } from '@api/checkout';
import { Modal } from '@components';
import { useDeliveryAddress } from '@contexts/delivery-address';
import { ERROR_MESSAGES } from '@lib/error-messages';
import { WORKING_HOURS } from '@lib/miscellaneous';
import { GAMTrackService } from '@services';
import { ICartItem, IQuickBuyFormValues, IQuickBuyPayload, IQuickBuyProps } from '@types';
import { validatePhoneNumber } from '@utils/formatting';
import { ButtonFPot } from '../../shared/buttons/button-fpot/ButtonFPot';
import { FormError } from '../../shared/form-elements';
import { Input } from '../../shared/form-elements/Input/Input';
import { PhoneInput } from '../../shared/form-elements/phone-input/PhoneInput';

import classes from './QuickBuyModal.module.scss';

const QuickBuyModal: FC<IQuickBuyProps> = ({ product, onOpenModal, qty, cartItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [pending, setPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successAdded, setSuccessAdded] = useState(false);
  const { lastUsedDeliveryAddress } = useDeliveryAddress();
  const [orderId, setOrderId] = useState<string | undefined>('');
  const { data: session } = useSession();
  const fullName = session?.user?.profile?.firstName + ' ' + session?.user?.profile?.lastName;
  const phone = session?.user?.phone;
  const { handleSubmit, control, setValue } = useForm<IQuickBuyFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      name: fullName ?? '',
      phone: phone ?? ''
    }
  });
  useEffect(() => {
    if (fullName) {
      setValue('name', fullName);
    } else {
      setValue('name', '');
    }
    if (phone) {
      setValue('phone', phone);
    } else {
      setValue('name', '');
    }
  }, [fullName, phone]);
  const quickBuyRequest = (cartData: IQuickBuyPayload) => {
    setErrorMessage('');
    setPending(true);
    postQuickBuy(cartData)
      .then((data) => {
        GAMTrackService.trackPurchase(data, 'швидке замовлення');
        if (typeof window !== 'undefined') {
          window.localStorage.setItem('quickBuyOrderId', data.orderId);
        }
        setOrderId(data.orderId);
        setSuccessAdded(true);
      })
      .catch((error) => {
        setErrorMessage(error.message || ERROR_MESSAGES.commonError);
      })
      .finally(() => setPending(false));
  };

  function handleOpenModal() {
    setIsOpen(true);
    if (onOpenModal && product) {
      const payload: ICartItem = {
        product,
        qty: qty || 1,
        productId: product.id,
        id: uuid()
      };
      onOpenModal(payload);
    }
  }

  function handleCloseModal() {
    setIsOpen(false);
    setPending(false);
    setSuccessAdded(false);
  }

  function handleQuickBuy(formData: IQuickBuyFormValues) {
    quickBuyRequest({
      items:
        cartItems && cartItems.length
          ? cartItems
          : product
          ? [
              {
                product,
                qty: qty || 1,
                productId: product.id,
                id: uuid()
              }
            ]
          : [],
      name: formData.name,
      phone: formData.phone,
      location: lastUsedDeliveryAddress?.id ?? '',
      locale: window.location.href.includes('flowerpot.ua/en') ? 'en' : 'ua'
    });
  }

  return (
    <>
      <Modal open={isOpen} closeModal={handleCloseModal}>
        <h3 className={classes.dialogTitle}>
          {successAdded ? (
            <div>
              Дякуємо за замовлення <p className={classes.orderId}>Номер замовлення: {orderId}</p>
            </div>
          ) : (
            'Швидка покупка'
          )}
        </h3>
        {!successAdded ? (
          <form onSubmit={handleSubmit(handleQuickBuy)}>
            <Controller
              render={({ field: { value, onChange }, fieldState }) => (
                <Input
                  label="Ім'я"
                  id="name"
                  error={fieldState.invalid}
                  errorMessage="Необхідно ввести ім'я"
                  value={value}
                  onChange={onChange}
                />
              )}
              name="name"
              control={control}
              rules={{ required: true }}
            />
            <Controller
              render={({ field: { ref: _, ...restField }, fieldState }) => (
                <PhoneInput {...restField} valid={!fieldState.invalid} onEnterKeyPress={handleSubmit(handleQuickBuy)} />
              )}
              name="phone"
              control={control}
              rules={{
                required: true,
                validate: validatePhoneNumber
              }}
            />
            <FormError message={errorMessage} className={classes.error} />
            <ButtonFPot label="Оформити замовлення" disabled={pending} spinner={pending} type="submit" fullWidth />
          </form>
        ) : (
          <>
            <p className={classes.successMessage}>
              Наш менеджер в найближчий час зателефонує вам для уточнення деталей. Працюємо без вихідних {WORKING_HOURS}
            </p>
          </>
        )}
      </Modal>
      <span onClick={handleOpenModal} className={classes.quickBuyBtn}>
        Швидке замовлення
      </span>
    </>
  );
};

export { QuickBuyModal };
