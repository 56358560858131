import { FC } from 'react';

import classes from './Spinner.module.scss';

interface IProps {
  size?: number;
}

const Spinner: FC<IProps> = ({ size }) => {
  return (
    <div
      className={classes.spinner}
      style={size ? { width: size, height: size, borderWidth: Math.round(size * 0.1) } : {}}
    />
  );
};

export { Spinner };
