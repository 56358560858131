import { FC } from 'react';

import classes from './Budge.module.scss';

interface IProps {
  label?: number | string;
}

const Budge: FC<IProps> = ({ label }) => {
  return label ? <span className={classes.budge}>{label}</span> : null;
};

export { Budge };
