import { CSSProperties, FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';

interface ICollapseProps {
  in?: boolean;
  children: ReactNode;
}

const TIMEOUT_DURATION = 300;

const defaultStyle: CSSProperties = {
  transition: `height ${TIMEOUT_DURATION}ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
  overflow: 'hidden',
  height: 0,
  width: '100%'
};

const transitionStyles: { [key: string]: CSSProperties } = {
  entering: { visibility: 'visible' },
  entered: { height: 'auto', visibility: 'visible' },
  exiting: { visibility: 'hidden' },
  exited: { visibility: 'hidden' }
};

const Collapse: FC<ICollapseProps> = ({ in: inProp, children }) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (nodeRef.current) {
      const element = nodeRef.current;
      if (inProp) {
        setHeight(element.scrollHeight);
      } else {
        // * We need to wait for the height to be set before we can set it to 0
        setTimeout(() => {
          setHeight(0);
        }, 10);
      }
    }
  }, [inProp]);

  return (
    <Transition in={inProp} timeout={TIMEOUT_DURATION} nodeRef={nodeRef}>
      {(state) => (
        <div
          ref={nodeRef}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
            height: state === 'entered' ? 'auto' : `${height}px`
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};

export { Collapse };
