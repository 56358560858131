import { ButtonHTMLAttributes, FC } from 'react';
import clsx from 'clsx';

import { Spinner } from '../../spinner/Spinner';

import classes from './ButtonFPot.module.scss';

interface IButtonFPotProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  classNameWrapper?: string;
  transparent?: boolean;
  fullWidth?: boolean;
  spinner?: boolean;
}

const ButtonFPot: FC<IButtonFPotProps> = ({ classNameWrapper, fullWidth, transparent, label, spinner, ...rest }) => {
  return (
    <button
      className={clsx(
        classes.btn,
        { [classes.fullWidth]: fullWidth, [classes.transparentBackground]: transparent },
        classNameWrapper
      )}
      aria-label={label}
      {...rest}
    >
      {spinner ? <Spinner size={20} /> : null}
      {label}
    </button>
  );
};

export { ButtonFPot };
