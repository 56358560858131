import { FC, SyntheticEvent, useState } from 'react';

import { ConfirmPhoneModal, RemindPassword } from '@components';
import { IAuthModalProps } from '@types';
import { AuthModalContainer } from './AuthModalContainer';

const AuthModal: FC<IAuthModalProps> = ({ children, onWrapperClick }) => {
  const [isAuthModalOpen, setAuthModalOpen] = useState(false);
  const [isConfirmPhoneModalOpen, setConfirmPhoneModalOpen] = useState(false);
  const [registrationPhoneNumber, setRegistrationPhoneNumber] = useState('');
  const [registrationPassword, setRegistrationPassword] = useState('');
  const [isOpenRemindModalModal, setIsOpenRemindModalModal] = useState(false);

  const handleOpenModal = () => {
    setAuthModalOpen(true);
  };

  const handleCloseAuthModal = () => {
    setAuthModalOpen(false);
  };

  const handleCloseConfirmPhoneModal = () => {
    setConfirmPhoneModalOpen(false);
  };

  const handleContinue = (phone: string, password: string) => {
    setRegistrationPhoneNumber(phone);
    setRegistrationPassword(password);
    setAuthModalOpen(false);
    setConfirmPhoneModalOpen(true);
  };

  const handleOpenPasswordRecovery = () => {
    setAuthModalOpen(false);
    setIsOpenRemindModalModal(true);
  };

  const closeRemind = () => {
    setIsOpenRemindModalModal(false);
  }

  const preventModalClose = (ev: SyntheticEvent | MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (onWrapperClick) {
      onWrapperClick();
    }
  };

  return (
    <div onClick={preventModalClose}>
      <div onClick={handleOpenModal}>{children}</div>
      <AuthModalContainer
        handleContinue={handleContinue}
        handleOpenPasswordRecovery={handleOpenPasswordRecovery}
        handleCloseAuthModal={handleCloseAuthModal}
        authModal={isAuthModalOpen}
      />
      <ConfirmPhoneModal
        closeModal={handleCloseConfirmPhoneModal}
        open={isConfirmPhoneModalOpen}
        initialPhone={registrationPhoneNumber}
        registrationPassword={registrationPassword}
        title="Підтвердіть номер телефону"
        signInFlow
      />
      {/*Modal for change password*/}
      <RemindPassword
        handleOpenRemindPasswordModal={isOpenRemindModalModal}
        handleOpenAuthModal={handleOpenModal}
        closeRemind={closeRemind}
      />
    </div>
  );
};

export { AuthModal };
