import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSwiper } from 'swiper/react';

import { Icon } from '@components';

import classes from './Carousel.module.scss';

interface ICarouselButtonArrowProps {
  classNameWrapper?: string;
  prevArrow?: string;
  productCardsLength?: number;
}

export const CarouselButtonArrows: FC<ICarouselButtonArrowProps> = ({
  classNameWrapper,
  prevArrow,
  productCardsLength
}) => {
  const [showArrows, setShowArrows] = useState<boolean>(false);
  const swiper = useSwiper();

  const showNavigationArrows = () => {
    if (productCardsLength) {
      if (productCardsLength > 5) return true;
      if (window.innerWidth < 980) return false;
      if (window.innerWidth < 1280 && productCardsLength <= 3) return false;
      if (window.innerWidth >= 1280 && window.innerWidth < 1660) {
        if (productCardsLength <= 2) return false;
        return true;
      }
      if (window.innerWidth >= 1660 && productCardsLength <= 5) return false;
    }
    return true;
  };

  const handleResize = () => {
    setShowArrows(showNavigationArrows());
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && window !== null) {
      window.addEventListener('resize', handleResize);
      setShowArrows(showNavigationArrows());
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [productCardsLength]);

  if (!productCardsLength) return null;

  return showArrows ? (
    <div className={classes.swiperNavBtn}>
      <button
        onClick={() => swiper.slidePrev()}
        className={clsx(classNameWrapper, prevArrow, classes.sliderButton, classes.sliderButtonNext)}
        aria-label="Наступний слайд"
      >
        <Icon type="sliderArrow" style={{ stroke: '#691B33' }} width={12} height={24} />
      </button>
      <button
        onClick={() => swiper.slideNext()}
        className={clsx(classNameWrapper, classes.sliderButton, classes.sliderButtonPrev)}
        aria-label="Попередній слайд"
      >
        <Icon type="sliderArrow" style={{ stroke: '#691B33' }} width={12} height={24} />
      </button>
    </div>
  ) : null;
};
