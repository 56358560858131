import { FC } from 'react';
import clsx from 'clsx';

import classes from './HighlightIcon.module.scss';

interface IHighlightIconProps {
  label?: string;
  classNameWrapper?: string;
}
const HighlightIcon: FC<IHighlightIconProps> = ({ label, classNameWrapper }) => {
  return <div className={clsx(classes.highlightIcon, classNameWrapper)}>{label}</div>;
};

export { HighlightIcon };
