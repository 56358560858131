import { ChangeEvent, CSSProperties, FC, MutableRefObject } from 'react';
import clsx from 'clsx';

import classes from './TextArea.module.scss';

interface ITextAreaProps {
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  classNameWrapper?: string;
  label?: string;
  rows?: number;
  id?: string;
  required?: boolean;
  placeholder?: string;
  style?: CSSProperties;
  innerRef?: MutableRefObject<HTMLTextAreaElement>;
}

const TextArea: FC<ITextAreaProps> = ({
  classNameWrapper,
  value,
  onChange,
  id,
  required,
  label,
  rows,
  placeholder,
  style,
  innerRef
}) => {
  return (
    <div className={clsx(classes.textAreaBox, classNameWrapper)}>
      <textarea
        value={value}
        onChange={onChange}
        className={classes.textAreaField}
        id={id}
        required={required}
        rows={rows || 5}
        placeholder={placeholder}
        style={style}
        ref={innerRef}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export { TextArea };
