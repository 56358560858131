import { ChangeEvent, FC, useState } from 'react';
import clsx from 'clsx';

import { Icon } from '../../icon/Icon';

import classes from './InputPassword.module.scss';

interface IInputPasswordProps {
  onChange?: (event: string | ChangeEvent<Element>) => void;
  value?: string;
  classNameWrapper?: string;
  error?: boolean;
  errorMessage?: string;
  label: string;
  id: string;
}

const InputPassword: FC<IInputPasswordProps> = ({
  classNameWrapper,
  id,
  error,
  errorMessage,
  label,
  value,
  onChange,
  ...rest
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const handleShowPassword = () => {
    setPasswordVisible((prevState) => !prevState);
  };
  return (
    <div className={clsx(classes.inputBox, classNameWrapper)}>
      <input
        type={passwordVisible ? 'text' : 'password'}
        className={classes.inputField}
        id={id}
        value={value}
        onChange={onChange}
        {...rest}
      />
      {error && <p className={classes.error}>{errorMessage}</p>}
      <label htmlFor={id}>{label}</label>
      <div className={classes.passwordToggle} onClick={handleShowPassword}>
        {passwordVisible ? (
          <Icon type="eyeOff" size={24} className={classes.passwordToggleIcon} style={{ color: '#999999' }} />
        ) : (
          <Icon type="eye" size={24} className={classes.passwordToggleIcon} style={{ stroke: '#333' }} />
        )}
      </div>
    </div>
  );
};
export { InputPassword };
