import { useRouter } from 'next/navigation';
import clsx from 'clsx';
import { A11y, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 as uuid } from 'uuid';

import { useCartData } from '@contexts/cart-data';
import { ProductPrice } from '../shared/product-price/ProductPrice';
import { ProductStockStatus } from '../shared/product-stock-status/ProductStockStatus';
import { InfoSection } from './info-section/InfoSection';

import 'swiper/css';
import 'swiper/css/pagination';

import { FC, MouseEvent, SyntheticEvent } from 'react';
import Image from 'next/image';
import Link from 'next/link';

import { DiscountIcon, ProductCardIcons, SwiperPagination } from '@components';
import { HighlightIcon } from '@components/product/highlight-icon/HighlightIcon';
import { useIsMobile } from '@contexts/mobile-detector-provider';
import { BOUQUET_OF_THE_DAY } from '@lib/constants/common-constants';
import { ICountriesMap } from '@lib/countriesMap';
import { GAMTrackService } from '@services';
import {
  attributesToFilter,
  IAttribute,
  ICartItem,
  IProduct,
  IProductAttributesDictionary,
  IProductOptions,
  IUser
} from '@types';
import { sortAttributesByOrder } from '@utils/formatting';
import { BuyButton } from './buy-button/BuyButton';
import { FavoriteIcon } from './FavoriteIcon/FavoriteIcon';

import classes from './CatalogProductCard.module.scss';

interface IProps {
  item: IProduct;
  withoutFavorite?: boolean;
  isSelected?: boolean;
  profile?: IUser | null;
  containerClassName?: string;
  index?: number;
  onCardClick?: (productId: string, index: number) => void;
  imagePriority?: boolean;
}

const CatalogProductCard: FC<IProps> = ({
  onCardClick,
  index,
  item,
  withoutFavorite,
  isSelected = false,
  containerClassName,
  imagePriority
}) => {
  const router = useRouter();
  const { cart, setCart } = useCartData();
  const isMobile = useIsMobile();
  const isInCart = cart && cart.length ? Boolean(cart.find((i) => i.product.id === item.id)) : false;

  const productParams: IAttribute[] = [];
  (Object.keys(item.attributes) as IProductAttributesDictionary[])
    .sort(sortAttributesByOrder(item.attributes))
    .forEach((attributeKey) => {
      const attribute = item.attributes[attributeKey as IProductAttributesDictionary];
      if (attribute?.value) {
        productParams.push(attribute);
      }
    });

  const cardClickHandler = (ev: SyntheticEvent | MouseEvent) => {
    ev.stopPropagation();
    if (onCardClick) {
      onCardClick(item.id, index || 0);
    }
  };

  const addToCartHandler = (ev: SyntheticEvent | MouseEvent, productOptions?: IProductOptions) => {
    ev.stopPropagation();
    ev.preventDefault();
    if (item.inStockStatus === 'soldout') return;

    if (onCardClick) {
      onCardClick(item.id, index || 0);
    }
    if (!isInCart) {
      const payload: ICartItem = {
        product: item,
        qty: 1,
        productId: item.id,
        id: uuid(),
        ...(productOptions ? { productOptions } : {})
      };
      setCart((c) => [...c, payload]);
      GAMTrackService.trackAddToCart(payload);
    } else {
      router.push('/tp/cart');
    }
  };
  const submitPostcardMessage = (e: SyntheticEvent | MouseEvent, message?: string) => {
    addToCartHandler(e, { postcardText: message });
  };

  // cut to 3 items for now
  const otherAttrs = productParams.filter(({ code }) => !attributesToFilter.includes(code)).splice(0, 3);
  const countryAttr = item.attributes.country as IAttribute<ICountriesMap>;

  return (
    <article
      className={clsx(classes.cardContainer, containerClassName, {
        [classes.cardSelected]: isSelected,
        [classes.disabled]: item.inStockStatus === 'soldout'
      })}
    >
      <Link
        href={`/${item.seoKey}`}
        className={classes.cardLink}
        onClick={cardClickHandler}
        aria-label={item.displayName}
        rel="preload"
      >
        <div className={classes.imageContainer}>
          {item.images?.length > 1 ? (
            <Swiper
              loop={false}
              slidesPerView={1}
              spaceBetween={50}
              speed={500}
              modules={[Navigation, Pagination, A11y]}
              pagination={{ el: '.pagination', clickable: false }}
              className={classes.swiper}
            >
              {item.images.map((image, index) => (
                <SwiperSlide key={index}>
                  <Image
                    src={
                      (isMobile ? image?.mobilePreview : image.preview) ||
                      image.preview ||
                      '/assets/images/flower-no-image.svg'
                    }
                    className={classes.image}
                    alt={item.displayName}
                    quality={100}
                    priority={imagePriority && index === 0}
                    fill
                  />
                </SwiperSlide>
              ))}
              <SwiperPagination />
            </Swiper>
          ) : (
            <Image
              className={classes.image}
              src={
                (isMobile ? item.images[0]?.mobilePreview : item.images[0]?.preview) ||
                item.images[0]?.preview ||
                '/assets/images/flower-no-image.svg'
              }
              alt={item.displayName}
              quality={100}
              priority={imagePriority}
              fill
            />
          )}
        </div>
        <div className={classes.infoContainer}>
          <ProductStockStatus status={item.inStockStatus} />
          <InfoSection name={item.displayName} attributes={otherAttrs} country={countryAttr?.value} />
          <div className={classes.cardFooter}>
            <ProductPrice price={item.price} specialPrice={item.specialPrice} className={classes.cardFooterPrice} />
            {item.inStockStatus !== 'soldout' ? (
              <BuyButton
                displayName={item.displayName}
                catalogCategoryProductsGroup={item.catalogCategoryProductsGroup}
                addToCartHandler={addToCartHandler}
                isInCart={isInCart}
                onSubmit={submitPostcardMessage}
              />
            ) : null}
          </div>
        </div>
        <ProductCardIcons>
          <DiscountIcon price={item.price} specialPrice={item.specialPrice} />
          {item?.highlight ? <HighlightIcon label={BOUQUET_OF_THE_DAY} classNameWrapper={classes.highlightIcon}/> : null}
          {!withoutFavorite && item.inStockStatus !== 'soldout' ? <FavoriteIcon productId={item.id} /> : null}
        </ProductCardIcons>
      </Link>
    </article>
  );
};

export { CatalogProductCard };
