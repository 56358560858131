import { FC, useState } from 'react';

import { Modal, RemindPasswordContainer } from '@components';
import { NewPasswordForm } from './NewPasswordForm/NewPasswordForm';

import classes from './RemindPassword.module.scss';

interface IRemindPasswordProps {
  handleOpenAuthModal: () => void;
  closeRemind: () => void;
  handleOpenRemindPasswordModal: boolean;
}

const RemindPassword: FC<IRemindPasswordProps> = ({
  handleOpenRemindPasswordModal,
  handleOpenAuthModal,
  closeRemind
}) => {
  const [showNewPasswordForm, setShowNewPasswordForm] = useState(false);
  const [authId, setAuthId] = useState('');
  const [resetPasswordToken, setResetPasswordToken] = useState('');
  const [resetKey, setResetKey] = useState('');

  const onSuccessVerify = () => {
    setShowNewPasswordForm(true);
  };

  const closeModal = () => {
    closeRemind();
    handleOpenAuthModal()
  };

  const closeRemindPasswordModal = () => {
    closeRemind();
    setShowNewPasswordForm(false);
  };

  return (
    <Modal open={handleOpenRemindPasswordModal} closeModal={closeRemindPasswordModal}>
      <h3 className={classes.title}>{showNewPasswordForm ? 'Введіть новий пароль' : 'Нагадати пароль'}</h3>
      {showNewPasswordForm ? (
        <NewPasswordForm
          resetKey={resetKey}
          resetPasswordToken={resetPasswordToken}
          authId={authId}
          closeModal={closeModal}
        />
      ) : (
        <RemindPasswordContainer
          setResetKey={setResetKey}
          resetPasswordToken={resetPasswordToken}
          setResetPasswordToken={setResetPasswordToken}
          authId={authId}
          setAuthId={setAuthId}
          onSuccessVerify={onSuccessVerify}
        />
      )}
    </Modal>
  );
};
export { RemindPassword };
