export enum ShippingMethodAlias {
  NOVA_POST = 'novaposhta',
  COURIER = 'delivery'
}
export interface IShippingMethods {
  id: string;
  name: string;
  alias: string | ShippingMethodAlias;
  description: string;
  priority: number;
}