import { API_URL } from '@lib/constants/environment';
import {
  IFeedbackProductRequestPayload,
  IFeedbackProfilePayload,
  IFeedbackProfileResponse,
  IFeedbackResponse,
  IFeedbackService,
  IPagedResponse,
  IProductReview
} from '@types';
import { handleError } from '@utils/error-handling';

export async function getFeedbackProductData({
  id,
  query
}: IFeedbackProductRequestPayload): Promise<IFeedbackResponse> {
  const res = await fetch(`${API_URL}/catalog/product/${id}/reviews${query ? '?' + query : ''}`, {
    headers: { 'Content-Type': 'application/json' },
    cache: 'no-cache'
  });
  return handleError<IFeedbackResponse>(res);
}

export async function getFeedbackProfileData({
  token,
  query
}: IFeedbackProfilePayload): Promise<IPagedResponse<IFeedbackProfileResponse[]>> {
  const res = await fetch(`${API_URL}/account/product-reviews${query ? '?' + query : ''}`, {
    headers: { 'Content-Type': 'application/json', Authorization: token || '' },
    cache: 'no-cache'
  });
  return handleError<IPagedResponse<IFeedbackProfileResponse[]>>(res);
}

export async function postFeedbackProductData(data: FormData): Promise<IProductReview> {
  const res = await fetch(`${API_URL}/form/product-review`, {
    method: 'POST',
    body: data
  });
  return handleError<IProductReview>(res);
}

export async function postFeedbackServiceData(
  feedback: string,
  token: string,
  order: string
): Promise<IFeedbackService> {
  const res = await fetch(`${API_URL}/form/order-feedback`, {
    method: 'POST',
    headers: {
      Authorization: token || '',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ order, feedback }),
    cache: 'no-cache'
  });
  return handleError<IFeedbackService>(res);
}
