import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ButtonFPot, FormError, PhoneInput } from '@components';
import { IConfirmPhoneFormValues } from '@types';
import { validatePhoneNumber } from '@utils/formatting';
import { useConfirmPhone } from '../confirm-phone-provider';

import classes from './ConfirmPhoneForm.module.scss';

interface IProps {
  title?: string;
}

const ConfirmPhoneForm: FC<IProps> = ({ title }) => {
  const { pending, phone, sendCode, errorMessage } = useConfirmPhone();
  const { handleSubmit, control } = useForm<IConfirmPhoneFormValues>({
    mode: 'onSubmit',
    defaultValues: { phone },
    shouldUnregister: true
  });

  const onSubmitPhone = ({ phone }: IConfirmPhoneFormValues) => {
    sendCode(phone);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitPhone)}>
      <h3 className={classes.title}>{title}</h3>
      <Controller
        render={({ field: { ref: _, ...restField }, fieldState }) => (
          <PhoneInput
            {...restField}
            valid={!fieldState.invalid}
            disabled={pending}
            onEnterKeyPress={handleSubmit(onSubmitPhone)}
            classNameWrapper={classes.phoneInput}
          />
        )}
        name="phone"
        control={control}
        rules={{
          required: true,
          validate: validatePhoneNumber
        }}
      />
      <FormError message={errorMessage} />
      <ButtonFPot
        classNameWrapper={classes.sendButton}
        label="Відправити смс-код"
        disabled={pending}
        spinner={pending}
        type="submit"
        fullWidth
      />
    </form>
  );
};
export { ConfirmPhoneForm };
