import { FC } from 'react';

import classes from './CircularProgress.module.scss';

interface CircularProgressProps {
  color?: string;
  size?: number;
}

const CircularProgress: FC<CircularProgressProps> = ({ color = 'primary', size }) => {
  return (
    <div className={`${classes.circularProgress} ${classes[color]}`} style={{ width: size, height: size }}>
      <svg className={classes.svg} viewBox="22 22 44 44">
        <circle className={classes.circle} cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6" />
      </svg>
    </div>
  );
};

export { CircularProgress };
