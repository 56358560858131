import { createContext, FC, useContext, useEffect, useState } from 'react';

import { postSendCode, postVerifyCode } from '@api/phone-confirmation';
import { ERROR_MESSAGES } from '@lib/error-messages';
import { IConfirmPhoneContext, IConfirmPhoneProviderProps } from '@types';

const ConfirmPhoneContext = createContext<IConfirmPhoneContext | undefined>(undefined);

const ConfirmPhoneProvider: FC<IConfirmPhoneProviderProps> = ({ children, phoneNumber, signInFlow }) => {
  const [phone, setPhone] = useState(phoneNumber || '');
  const [pending, setPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [timeLeft, setTimeLeft] = useState(0);
  const [phoneEditing, setPhoneEditing] = useState(true);

  const sendCode = (number: string) => {
    if (number !== phone) {
      setPhone(number);
    }

    setErrorMessage('');
    setPending(true);
    postSendCode(number)
      .then(({ success, timeout }) => {
        setTimeLeft(timeout || 0);
        if (success) {
          setPhoneEditing(false);
        }
      })
      .catch(() => {
        setErrorMessage(ERROR_MESSAGES.commonError);
      })
      .finally(() => setPending(false));
  };

  const verifyCode = async (number: string, code: string) => {
    setPhone(number);
    setErrorMessage('');
    setPending(true);
    return postVerifyCode(number, code)
      .catch(() => {
        setErrorMessage(ERROR_MESSAGES.commonError);
      })
      .finally(() => setPending(false));
  };

  useEffect(() => {
    // * after registration flow (when phone already exist) send a verification code immediately
    if (signInFlow && phone) {
      sendCode(phone);
    }
  }, [phone]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    }
  }, [timeLeft]);

  return (
    <ConfirmPhoneContext.Provider
      value={{
        pending,
        phone,
        setPhone,
        sendCode,
        verifyCode,
        phoneEditing,
        setPhoneEditing,
        errorMessage,
        setErrorMessage,
        timeLeft
      }}
    >
      {children}
    </ConfirmPhoneContext.Provider>
  );
};

const useConfirmPhone = () => {
  const context = useContext(ConfirmPhoneContext);
  if (!context) {
    throw new Error('useConfirmPhone must be used within a ConfirmPhoneProvider');
  }
  return context;
};

export { ConfirmPhoneProvider, useConfirmPhone };
