export const breakpoints = {
  240: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
    navigation: false,
    pagination: {
      el: '.pagination',
      clickable: true
    }
  },
  360: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 0,
    navigation: false,
    pagination: {
      el: '.pagination',
      clickable: true
    }
  },
  580: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 0,
    navigation: false,
    pagination: {
      el: '.pagination',
      clickable: true
    }
  },
  980: {
    slidesPerView: 3,
    spaceBetween: 10,
    pagination: false
  },
  1280: {
    slidesPerView: 4,
    spaceBetween: 20,
    pagination: false
  },
  1660: {
    slidesPerView: 5,
    spaceBetween: 20
  }
};

export const breakpointsHighlight = {
  360: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 10,
    navigation: false,
    pagination: {
      el: '.pagination',
      clickable: true
    }
  },
  740: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 20,
    navigation: false,
    pagination: {
      el: '.pagination',
      clickable: true
    }
  }
};
