import { API_URL } from '@lib/constants/environment';

export async function getProduct(id: string) {
  const res = await fetch(`${API_URL}/catalog/product/${id}`, { cache: 'no-store' });
  return res.json();
}

export async function addProductToFavorites(token: string, id: string) {
  return fetch(`${API_URL}/catalog/product/watch/${id}`, {
    method: 'POST',
    headers: { Authorization: token },
    cache: 'no-cache'
  });
}

export async function removeProductFromFavorites(token: string, id: string) {
  return fetch(`${API_URL}/catalog/product/watch/${id}`, {
    method: 'DELETE',
    headers: { Authorization: token },
    cache: 'no-cache'
  });
}
