import { Dispatch, FC, SetStateAction, useState } from 'react';

import { Tab, Tabs } from '@components';
import { ConfirmPasswordForm } from './ConfirmPasswordForm/ConfirmPasswordForm';
import { RemindPasswordForm } from './RemindPasswordForm/RemindPasswordForm';

import classes from '../RemindPassword.module.scss';

interface IRemindPasswordContainerProps {
  authId: string;
  setAuthId: Dispatch<SetStateAction<string>>;
  onSuccessVerify: () => void;
  resetPasswordToken: string;
  setResetPasswordToken: Dispatch<SetStateAction<string>>;
  setResetKey: Dispatch<SetStateAction<string>>;
}

const RemindPasswordContainer: FC<IRemindPasswordContainerProps> = ({
  onSuccessVerify,
  authId,
  setAuthId,
  resetPasswordToken,
  setResetPasswordToken,
  setResetKey
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Tabs classNameWrapper={classes.tabsContainer}>
        <Tab
          onClick={handleChange}
          index={0}
          selectedValue={activeTab}
          label="Телефон"
          disabled={Boolean(resetPasswordToken)}
        />
        <Tab
          onClick={handleChange}
          index={1}
          selectedValue={activeTab}
          label="E-mail"
          disabled={Boolean(resetPasswordToken)}
        />
      </Tabs>
      {resetPasswordToken ? (
        <ConfirmPasswordForm
          token={resetPasswordToken}
          authId={authId}
          setResetKey={setResetKey}
          setResetPasswordToken={setResetPasswordToken}
          title={activeTab === 0 ? 'Введіть код відправлений на номер' : 'Введіть код відправлений на e-mail'}
          onSuccessVerify={onSuccessVerify}
        />
      ) : (
        <RemindPasswordForm activeTab={activeTab} setResetPasswordToken={setResetPasswordToken} setAuthId={setAuthId} />
      )}
    </>
  );
};
export { RemindPasswordContainer };
