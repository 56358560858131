import { FC } from 'react';

import { Modal } from '../../shared';
import { ConfirmPhoneForm } from './confirm-phone-form/ConfirmPhoneForm';
import { ConfirmPhoneProvider, useConfirmPhone } from './confirm-phone-provider';
import { VerifyCodeForm } from './verify-code-form/VerifyCodeForm';

interface IProps {
  open: boolean;
  closeModal: () => void;
  initialPhone?: string;
  registrationPassword?: string;
  title?: string;
  signInFlow?: boolean;
}

interface IConfirmPhoneModalContentProps {
  closeModal: () => void;
  registrationPassword?: string;
  title?: string;
}

const ConfirmPhoneModal: FC<IProps> = ({ open, closeModal, initialPhone, registrationPassword, title, signInFlow }) => (
  <Modal open={open} closeModal={closeModal}>
    <ConfirmPhoneProvider phoneNumber={initialPhone} signInFlow={signInFlow}>
      <ConfirmPhoneModalContent title={title} closeModal={closeModal} registrationPassword={registrationPassword} />
    </ConfirmPhoneProvider>
  </Modal>
);

const ConfirmPhoneModalContent: FC<IConfirmPhoneModalContentProps> = ({ closeModal, registrationPassword, title }) => {
  const { phoneEditing } = useConfirmPhone();
  return phoneEditing ? (
    <ConfirmPhoneForm title={title} />
  ) : (
    <VerifyCodeForm closeModal={closeModal} registrationPassword={registrationPassword} />
  );
};

export { ConfirmPhoneModal };
