import { FC, ReactNode, useState } from 'react';
import Link from 'next/link';
import clsx from 'clsx';

import { Collapse } from '@components/shared';
import { Icon } from '../icon/Icon';

import classes from './ExpandPanel.module.scss';

interface IProps {
  defaultExpanded?: boolean;
  label: ReactNode | undefined;
  children: ReactNode;
  className?: string;
  classNameLabelContainer?: string;
  iconSize?: number;
}

export const ExpandPanel: FC<IProps> = ({
  label,
  children,
  defaultExpanded,
  className,
  iconSize,
  classNameLabelContainer
}) => {
  const defaultExpandedActual = defaultExpanded !== undefined && defaultExpanded;
  const [isExpanded, setIsExpanded] = useState(defaultExpandedActual);

  const handleToggle = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <div className={clsx(classes.root, { [className || '']: !!className })}>
      <div className={classes.panel}>
        <div className={clsx(classes.labelContainer, classNameLabelContainer)} onClick={handleToggle}>
          <Icon
            type="arrowExpand"
            width={iconSize || 12}
            height={iconSize || 14}
            className={clsx(classes.arrow, { [classes.arrowExpand]: isExpanded })}
          />
          {typeof label === 'string' ? (
            <Link href="#" className={classes.heading} onClick={handleToggle}>
              {label}
            </Link>
          ) : (
            label
          )}
        </div>
        <Collapse in={isExpanded}>
          <div className={classes.details}>{children}</div>
        </Collapse>
      </div>
    </div>
  );
};
