'use client';

import { FC, ReactNode, useCallback, useEffect, useRef } from 'react';
import ReactDom from 'react-dom';
import clsx from 'clsx';

import { iOS, isMacOSDevice, isWindowsDevice } from '@lib/constants/environment';
import { Icon } from '../icon/Icon';

import classes from './Modal.module.scss';

interface IModalProps {
  open: boolean;
  closeIconClassName?: string;
  closeModal: () => void;
  children: ReactNode;
  withOutPadding?: boolean;
  classNameContainer?: string;
}

const Modal: FC<IModalProps> = ({
  open,
  closeIconClassName,
  withOutPadding,
  closeModal,
  children,
  classNameContainer,
  ...rest
}) => {
  const modalRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    modalRef.current = window.document.getElementById('modal');
    if (modalRef.current && typeof window !== 'undefined') {
      if (open) {
        window.document.body.style.overflow = 'hidden';
        if (isWindowsDevice(window.navigator)) {
          window.document.body.style.paddingRight = '17px';
        } else if (isMacOSDevice(window.navigator)) {
          window.document.body.style.paddingRight = '15px';
        }

        if (iOS(window.navigator.userAgent)) {
          const offset = window.document.body.getBoundingClientRect().top;
          window.document.body.style.top = offset * -1 + 'px';
          window.document.body.style.position = 'fixed';
        }

        window.document.addEventListener('keydown', handleEscKey);
      } else {
        window.document.removeEventListener('keydown', handleEscKey);
      }
    }

    return () => {
      if (typeof window !== 'undefined' && open) {
        window.document.body.style.overflow = 'auto';
        window.document.body.style.paddingRight = 'initial';
        window.document.removeEventListener('keydown', handleEscKey);
        if (iOS(window.navigator.userAgent)) {
          const offset = parseInt(window.document.body.style.top, 10);
          window.document.body.style.position = 'initial';
          if (!isNaN(offset)) {
            window.scrollTo({ top: offset, behavior: 'instant' });
          }
        }
      }
    };
  }, [open]);

  const handleClose = useCallback(() => {
    window.document.body.style.overflow = 'auto';
    window.document.body.style.paddingRight = 'initial';
    if (iOS(window.navigator.userAgent)) {
      window.document.body.style.position = 'initial';
    }
    if (closeModal) {
      closeModal();
    }
  }, [closeModal]);

  const handleEscKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    },
    [closeModal]
  );

  return open && modalRef.current
    ? ReactDom.createPortal(
        <>
          {open ? (
            <div className={classes.wrapper} onClick={handleClose} {...rest}>
              <div className={classes.modalContainer} onClick={(e) => e.stopPropagation()}>
                <div className={clsx(classes.wrapperIcon, closeIconClassName)}>
                  <Icon onClick={handleClose} type="cross" size={15} leftOffset="auto" />
                </div>
                <div
                  className={clsx(classes.container, { [classes.withOutPadding]: withOutPadding }, classNameContainer)}
                >
                  {children}
                </div>
              </div>
            </div>
          ) : null}
        </>,
        modalRef.current
      )
    : null;
};

export { Modal };
