import { FC, useEffect } from 'react';
import clsx from 'clsx';

import classes from './OtpInput.module.scss';

interface IProps {
  onChange: (value: string) => void;
  disabled?: boolean;
  value?: string;
  valid?: boolean;
  onBlur?: () => void;
  name?: string;
  classNameWrapper?: string;
}

const OtpInput: FC<IProps> = ({ onChange, disabled, value, valid, onBlur, name, classNameWrapper }) => {
  useEffect(() => {
    if ('OTPCredential' in window) {
      const ac = new AbortController();
      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: ac.signal
        })
        .then((otp) => {
          onChange(otp?.code || '');
        })
        .catch((err) => {
          console.error(err);
        });

      setTimeout(
        () => {
          ac.abort();
        },
        2 * 60 * 1000
      );
    }
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    onChange(inputValue);
  };

  return (
    <div className={classNameWrapper || ''}>
      <input
        type="text"
        inputMode="numeric"
        pattern="\d{6}"
        disabled={disabled}
        onBlur={onBlur}
        name={name}
        autoComplete="one-time-code"
        className={clsx(classes.otpInput, { [classes.otpInputError]: !valid })}
        value={value}
        onChange={handleInputChange}
        autoFocus
      />
      {!valid ? <p className={classes.errorText}>Невірний код</p> : null}
    </div>
  );
};

export { OtpInput };
