'use client';

import { FC, MouseEvent } from 'react';
import Link from 'next/link';
import clsx from 'clsx';

import { ISelectedItem } from '@types';
import { Icon, IconButton, UnderlinedButton, CircularProgress } from '../shared';
import { CategoryOption } from './components/CategoryOption';
import { ProductOption } from './components/ProductOption';
import { SearchHistoryOption } from './components/SearchHistoryOption';
import { useSearchControl } from './use-search-control';

import classes from './Search.module.scss';

interface IProps {
  onClose: () => void;
  isScrolled?: boolean;
}

const Search: FC<IProps> = ({ onClose, isScrolled }) => {
  const {
    searchResults,
    clearSearchHistory,
    searchHistory,
    onSearchInputChange,
    searchQuery,
    searchPending,
    setSearchQuery,
    updateSelectionHistory,
    removeFromHistory
  } = useSearchControl();

  const handleSelectItem = (item: ISelectedItem) => {
    updateSelectionHistory({ label: item.label, seoKey: item.seoKey });
    onClose();
  };

  const handleSelectHistoryItem = (label: string) => {
    setSearchQuery(label);
  };

  const onRemoveHistoryItem = (label: string) => (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    removeFromHistory(label);
  };

  const searchHistoryOptions = searchHistory.map((i) => (
    <SearchHistoryOption
      key={i.label}
      onSelect={handleSelectHistoryItem}
      label={i.label}
      onRemove={onRemoveHistoryItem}
    />
  ));

  const productsOptions = searchResults?.products
    ? searchResults?.products.map((i) => (
        <ProductOption
          key={i.displayName}
          onSelect={handleSelectItem}
          images={i.images}
          price={i.price}
          specialPrice={i.specialPrice}
          productName={i.displayName}
          searchQuery={searchQuery}
          seoKey={i.seoKey}
        />
      ))
    : [];

  const categoriesOptions = searchResults?.catalogCategory
    ? searchResults?.catalogCategory.map((i) => (
        <CategoryOption key={i.seoKey} categoryName={i.label} onSelect={handleSelectItem} seoKey={i.seoKey} />
      ))
    : [];

  return (
    <div className={classes.container}>
      <div className={clsx(classes.searchHeader, { [classes.searchHeaderSqueezed]: !isScrolled })}>
        <Icon type="search" size={24} className={classes.searchHeaderIcon} />
        <input
          onChange={onSearchInputChange}
          autoFocus
          value={searchQuery}
          type="text"
          placeholder="Введіть запит"
          className={classes.searchInput}
        />
        {searchPending ? <CircularProgress size={20} /> : null}
        <IconButton onClick={onClose} icon="cancel" iconSize={24} ariaLabel="Скасувати" />
      </div>
      <div className={classes.innerContainer}>
        <div className={classes.listContainer}>
          {searchHistoryOptions.length ? (
            <div className={classes.historyContainer}>
              <p className={classes.groupTitle}>
                <span>Історія пошуку</span>
                <UnderlinedButton onClick={clearSearchHistory} label="Очистити історію" />
              </p>
              <div className={classes.historyItemsWrapper}>{searchHistoryOptions}</div>
            </div>
          ) : null}
          {productsOptions.length ? (
            <div className={classes.productsContainer}>
              <p className={classes.groupTitle}>Товари</p>
              {productsOptions}
            </div>
          ) : null}
          {categoriesOptions.length ? (
            <div className={classes.categoriesContainer}>
              <p className={classes.groupTitle}>Пошук у категоріях</p>
              <div className={classes.catagoriesItemsWrapper}>{categoriesOptions}</div>
            </div>
          ) : null}
        </div>
        {searchResults?.total && searchResults.total > 0 && (productsOptions.length || categoriesOptions.length) ? (
          <div className={classes.showAll}>
            <Link className={classes.showAllLink} href={`/search?searchText=${searchQuery}`}>
              Показати всі результати ({searchResults?.total})
            </Link>
          </div>
        ) : null}
        {searchResults?.total === 0 && searchQuery && searchQuery.length > 3 && !searchPending ? (
          <p className={classes.emptyList}>За вашим запитом нічого не знайдено :(</p>
        ) : null}
      </div>
    </div>
  );
};

export { Search };
