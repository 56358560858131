'use client';

import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

import { useIsMobile } from '@contexts/mobile-detector-provider';
import { Icon } from '../icon/Icon';

import drawerClasses from './Drawer.module.scss';

interface IDrawerProps {
  open: boolean;
  closeIconClassName?: string;
  onClose?: () => void;
  children: ReactNode;
  classes?: { wrapper?: string; container?: string };
  anchor: 'top' | 'bottom' | 'left' | 'right';
  withCloseMark?: boolean;
  withoutBackdrop?: boolean;
  drawerZIndex?: boolean;
  preventScroll?: boolean;
}

const Drawer: FC<IDrawerProps> = ({
  open,
  closeIconClassName,
  onClose,
  classes,
  children,
  anchor,
  withCloseMark,
  withoutBackdrop,
  drawerZIndex,
  preventScroll
}) => {
  const isMobile = useIsMobile();
  const drawerRef = useRef<HTMLElement | null>(null);
  const [showDrawer, setShowDrawer] = useState(open);
  const [mounted, setMounted] = useState(false);
  const [animationClass, setAnimationClass] = useState(open ? 'open' : 'close');
  const animationDuration = 225;

  useEffect(() => {
    setMounted(true);

    if (typeof window !== 'undefined') {
      drawerRef.current = window.document.getElementById('drawer');
    }

    let timer: number;

    if (drawerRef.current && typeof window !== 'undefined') {
      if (open) {
        setShowDrawer(true);
        window.document.body.style.overflow = 'hidden';

        // * we need to wait for the drawer to be mounted before adding the open class animation
        timer = window.setTimeout(() => {
          setAnimationClass('open');
        }, 50);

        if (!isMobile) {
          window.document.addEventListener('keydown', handleEscKey);
        }
      } else {
        setAnimationClass('close');
        if (!isMobile) {
          window.document.removeEventListener('keydown', handleEscKey);
        }

        // * we need to wait the close animation before close the drawer
        timer = window.setTimeout(() => {
          setShowDrawer(false);
        }, animationDuration);
      }
    }

    return () => {
      if (typeof window !== 'undefined') {
        if (timer) {
          clearTimeout(timer);
        }
        if (!preventScroll) {
          window.document.body.style.overflow = 'auto';
          if (!isMobile) {
            window.document.removeEventListener('keydown', handleEscKey);
          }
        }
      }
    };
  }, [open]);

  const handleEscKey = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && onClose) {
      onClose();
    }
  };

  if (!mounted || !showDrawer || !drawerRef.current) {
    return null;
  }

  return createPortal(
    <div
      aria-hidden={showDrawer ? 'false' : 'true'}
      className={clsx(drawerClasses.wrapper, drawerClasses[animationClass], {
        [drawerClasses.withoutBackdrop || '']: withoutBackdrop,
        [drawerClasses.drawerZIndex || '']: drawerZIndex
      })}
      onClick={onClose}
    >
      <div
        className={clsx(
          drawerClasses.modalContainer,
          drawerClasses[`anchor-${anchor}`],
          drawerClasses[animationClass],
          { [classes?.container || '']: classes?.container }
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {withCloseMark ? (
          <div className={clsx(drawerClasses.wrapperIcon, closeIconClassName)}>
            <Icon onClick={onClose} type="cross" size={15} leftOffset="auto" />
          </div>
        ) : null}
        <div className={clsx({ [classes?.wrapper || '']: classes?.wrapper })} role="dialog">
          {children}
        </div>
      </div>
    </div>,
    drawerRef.current
  );
};

export { Drawer };
