export * from './checkout';
export * from './home-page';
export * from './search';
export * from './phone-confirmation';
export * from './auth';
export * from './cart';
export * from './catalog';
export * from './contact-us';
export * from './orders';
export * from './main-menu';
export * from './outlets';
export * from './shipping-methods';
export * from './filter-options';
export * from './common';
export * from './account';
export * from './product';
export * from './google-analytic-manager';
export * from './locations';
export * from './static-page'
export * from './feedback';
export * from './blogpost'
export * from './sales'