import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useRouter } from 'next/navigation';
import { signIn } from 'next-auth/react';

import { ButtonFPot, FormError, InputPassword, PhoneInput, UnderlinedButton } from '@components';
import { SocialButtons } from '@components/shared/SocialButtons/SocialButtons';
import { ERROR_MESSAGES } from '@lib/error-messages';
import { ILoginFormProps, ILoginFormValues } from '@types';
import { validatePhoneNumber } from '@utils/formatting';

import classes from './LoginForm.module.scss';

const LoginForm: FC<ILoginFormProps> = ({
  handleOpenPasswordRecovery,
  handleCloseAuthorization,
  handleCloseAuthModal,
  invokeVerifyPhone
}) => {
  const router = useRouter();
  const [errorMessage, setErrorMessage] = useState('');
  const [pending, setPending] = useState(false);

  const { handleSubmit, control, watch } = useForm<ILoginFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      password: '',
      phone: '',
      remember: false
    }
  });

  useEffect(() => {
    const subscription = watch((_value, { type }) => {
      if (type === 'change' && errorMessage) {
        setErrorMessage('');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, errorMessage]);

  const handleSignIn = ({ password, phone, remember }: ILoginFormValues) => {
    setErrorMessage('');
    setPending(true);
    signIn('credentials', {
      password,
      remember,
      authId: phone,
      redirect: false
    })
      .then((callback) => {
        setPending(false);

        if (callback?.error) {
          if (callback.error.includes('phoneVerificationRequired')) {
            invokeVerifyPhone(phone, password);
          } else {
            setErrorMessage(callback.error);
          }
        }

        if (callback?.ok && !callback?.error) {
          handleCloseAuthModal();

          if (callback.url) {
            const params = new URLSearchParams(new URL(callback.url).search);
            const redirectUrl = params.get('callbackUrl');
            if (redirectUrl) {
              router.replace(redirectUrl);
            }
          }
        }
      })
      .catch((error) => {
        setErrorMessage(error.message || ERROR_MESSAGES.commonError);
      });
  };

  return (
    <form onSubmit={handleSubmit(handleSignIn)}>
      <h2 className={classes.authTitle}>Вхід</h2>
      <Controller
        render={({ field: { ref: _, ...restField }, fieldState }) => (
          <PhoneInput {...restField} valid={!fieldState.invalid} onEnterKeyPress={handleSubmit(handleSignIn)} />
        )}
        name="phone"
        control={control}
        rules={{
          required: true,
          validate: validatePhoneNumber
        }}
      />
      <Controller
        render={({ field: { value, onChange }, fieldState }) => (
          <InputPassword
            label="Пароль"
            id="password"
            value={value}
            onChange={onChange}
            error={fieldState.invalid}
            errorMessage="Пароль має містити принаймні 8 символів"
          />
        )}
        name="password"
        control={control}
        rules={{ required: true }}
      />
      <div className={classes.rememberBlock}>
        <label className={classes.rememberMe}>
          <Controller
            render={({ field: { value, onChange } }) => (
              <input
                name="remember"
                type="checkbox"
                checked={Boolean(value)}
                onChange={onChange}
                className={classes.checkBox}
              />
            )}
            name="remember"
            control={control}
          />
          Запамʼятати мене
        </label>
        <UnderlinedButton
          className={classes.forgotPassword}
          onClick={handleOpenPasswordRecovery}
          disabled={pending}
          type="button"
          label="Нагадати пароль"
        />
      </div>
      <FormError message={errorMessage} />
      <ButtonFPot label="Увійти" disabled={pending} type="submit" fullWidth />
      <div className={classes.middleText}>
        <hr className={classes.hr} />
        <p className={classes.orText}>Вхід через соцмережі</p>
      </div>
      <SocialButtons
        googleLabel="Google"
        facebookLabel="Facebook"
        classNameWrapper={classes.socialButtons}
      />
      <div className={classes.signUp}>
        <p className={classes.signUpTitle}>Новий користувач?</p>
        <UnderlinedButton
          className={classes.signUpLink}
          onClick={handleCloseAuthorization}
          disabled={pending}
          label="Зареєструйтесь"
        />
      </div>
    </form>
  );
};

export { LoginForm };
