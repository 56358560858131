import { FC, ReactNode } from 'react';

interface ITabPanelProps {
  children?: ReactNode;
  className?: string;
  index: number;
  value: number;
}

export const TabPanel: FC<ITabPanelProps> = ({ children, value, index, ...rest }) => {
  return (
    <div role="tabpanel" hidden={value !== index} {...rest}>
      {value === index ? children : null}
    </div>
  );
};