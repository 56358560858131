import { FC } from 'react';
import Link from 'next/link';

import { ISelectedItem } from '@types';

import classes from '../Search.module.scss';

interface IProps {
  categoryName: string;
  qty?: number;
  seoKey: string;
  onSelect: (item: ISelectedItem) => void;
}

const CategoryOption: FC<IProps> = ({ categoryName, seoKey, onSelect }) => {
  return (
    <Link
      href={`/${seoKey}` || ''}
      onClick={() => onSelect({ label: categoryName, seoKey })}
      className={classes.categoriesItem}
    >
      {categoryName}
    </Link>
  );
};

export { CategoryOption };
