import { FC, useState } from 'react';

import { Modal } from '@components';
import { IAuthModalContainerProps } from '@types';
import { LoginForm } from './login-form/LoginForm';
import { RegistrationForm } from './registration-form/RegistrationForm';

const AuthModalContainer: FC<IAuthModalContainerProps> = (
  { handleOpenPasswordRecovery, handleContinue, handleCloseAuthModal, authModal }
) => {
  const [showAuthorizationFields, setShowAuthorizationFields] = useState(true);
  const handleOpenAuthorization = () => {
    setShowAuthorizationFields(true);
  };

  const handleCloseAuthorization = () => {
    setShowAuthorizationFields(false);
  };
  return (
    <Modal open={authModal} closeModal={handleCloseAuthModal}>
      {!showAuthorizationFields ? (
        <RegistrationForm handleContinue={handleContinue} handleOpenAuthorization={handleOpenAuthorization} />
      ) : (
        <LoginForm
          handleOpenPasswordRecovery={handleOpenPasswordRecovery}
          handleCloseAuthorization={handleCloseAuthorization}
          handleCloseAuthModal={handleCloseAuthModal}
          invokeVerifyPhone={handleContinue}
        />
      )}
    </Modal>
  );
};

export { AuthModalContainer };
