import React, { ReactNode } from 'react';

import { Icon } from '../../shared/icon/Icon';

import classes from './MobileModalHeader.module.scss';

interface IProps {
  title?: string | ReactNode;
  onClose?: () => void;
  backButton?: boolean;
}

const MobileModalHeader = ({ backButton, onClose, title }: IProps) => (
  <div className={classes.modalHeader}>
    {backButton ? <Icon type="chevronLeft" height={22} width={16} onClick={onClose} /> : null}
    <div className={classes.modalTitle}>{title}</div>
  </div>
);

export { MobileModalHeader };
