import { ChangeEvent, FC } from 'react';
import clsx from 'clsx';

import classes from './Switch.module.scss';

export interface IFlrSwitchProps {
  labelClassName?: string;
  wrapperClassName?: string;
  label?: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabled?: boolean;
  id: string;
}

export const Switch: FC<IFlrSwitchProps> = ({
  onChange,
  checked,
  labelClassName,
  label,
  wrapperClassName,
  disabled,
  id
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event, event.target.checked);
  };
  return (
    <label htmlFor={id} className={clsx(classes.container, wrapperClassName)}>
      <span className={clsx(classes.switch, { [classes.disabled || '']: disabled })}>
        <input
          type="checkbox"
          id={id}
          onChange={handleChange}
          checked={checked}
          className={classes.input}
          disabled={disabled}
        />
        <span className={clsx(classes.slider, { [classes.disabled || '']: disabled })}></span>
      </span>
      {label ? <span className={labelClassName}>{label}</span> : null}
    </label>
  );
};
