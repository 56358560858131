import { FC, useState } from 'react';
import clsx from 'clsx';

import classes from './StarRate.module.scss';

interface IStarRatingProps {
  currentRate: number;
  setRate?: (rate: number) => void;
  readOnly?: boolean;
  classNameStarWrapper?: string;
}

const starArray = Array(5).fill(0);
const StarRate: FC<IStarRatingProps> = ({ currentRate, setRate, readOnly, classNameStarWrapper }) => {
  const [hover, setHover] = useState<number>(0);

  const handleStarClick = (index: number) => {
    if (!readOnly) {
      if (setRate) {
        setRate(index);
      }
    }
  };

  const handleStarHover = (hoveredRate: number) => {
    if (!readOnly) {
      setHover(hoveredRate);
    }
  };

  return (
    <div className={clsx(classes.wrapper, classNameStarWrapper)}>
      {starArray.map((_, index) => {
        const isFilled = index + 1 <= (hover || currentRate);
        return (
          <div
            key={index}
            onClick={() => handleStarClick(index + 1)}
            onMouseEnter={() => handleStarHover(index + 1)}
            onMouseLeave={() => handleStarHover(currentRate)}
            className={clsx(classes.star, { [classes.filled]: isFilled })}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
              <path
                d="M16.9451 20.4001C16.7851 20.4001 16.6451 20.3601 16.5051 20.2801L12.0051 17.3601L7.50506 20.2801C7.22506 20.4601 6.86506 20.4401 6.60506 20.2601C6.34506 20.0601 6.22506 19.7201 6.30506 19.4001L7.72506 14.4001L3.50506 10.8401C3.26506 10.6401 3.16506 10.3001 3.26506 9.98013C3.36506 9.68013 3.64506 9.46012 3.96506 9.42012L9.02506 9.06013L11.3051 4.08013C11.4451 3.80013 11.7251 3.62012 12.0251 3.62012C12.3451 3.62012 12.6251 3.80013 12.7451 4.08013L15.0251 9.06013L20.0851 9.42012C20.4051 9.44012 20.6851 9.66013 20.7851 9.98013C20.8851 10.2801 20.7851 10.6201 20.5451 10.8401L16.3251 14.4001L17.7451 19.4001C17.8451 19.7201 17.7251 20.0601 17.4451 20.2601C17.2851 20.3401 17.1251 20.4001 16.9451 20.4001Z"
                fill={isFilled ? '#FFB200' : '#ccc'}
              />
            </svg>
          </div>
        );
      })}
    </div>
  );
};

export default StarRate;
