import { API_URL } from '@lib/constants/environment';
import { ERROR_MESSAGES } from '@lib/error-messages';
import {
  IForgotPasswordPayload,
  IForgotPasswordResponse,
  IRegisterResponse,
  IRegistrationFormValues,
  IResetPasswordPayload,
  IValidateKeyPayload,
  IValidateKeyResponse
} from '@types';
import { handleError } from '@utils/error-handling';

export async function postRegister(data: IRegistrationFormValues): Promise<IRegisterResponse> {
  const res = await fetch(`${API_URL}/auth/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
    cache: 'no-cache'
  });
  if (res && (res.status === 400 || res.status === 404)) {
    const respData = await res.json();
    const errors = Object.values(respData);
    if (errors && errors.length > 0) {
      throw new Error(ERROR_MESSAGES[errors[0] as keyof typeof ERROR_MESSAGES] || ERROR_MESSAGES.commonError);
    }
    throw new Error(ERROR_MESSAGES.commonError);
  } else if (res.status !== 200 && res.status !== 201) {
    throw new Error(ERROR_MESSAGES.commonError);
  } else {
    return res.json();
  }
}

export async function postForgotPassword(data: IForgotPasswordPayload): Promise<IForgotPasswordResponse> {
  const res = await fetch(`${API_URL}/auth/forgot-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
    cache: 'no-cache'
  });
  return handleError<IForgotPasswordResponse>(res, { body: data });
}

export async function postValidateResetKeyPassword(data: IValidateKeyPayload): Promise<IValidateKeyResponse> {
  const res = await fetch(`${API_URL}/auth/validate-reset-key`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
    cache: 'no-cache'
  });
  return handleError<IValidateKeyResponse>(res, { body: data });
}

export async function postResetPassword(data: IResetPasswordPayload): Promise<void> {
  const res = await fetch(`${API_URL}/auth/reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
    cache: 'no-cache'
  });
  return handleError<void>(res, { body: data });
}
