import { FC } from 'react';
import clsx from 'clsx';

import { Icon } from '../icon/Icon';

import classes from './SocialButtons.module.scss';

interface IProps {
  googleLabel?: string;
  facebookLabel?: string;
  classNameWrapper?: string;
  classNameBtn?: string;
  disabled?: boolean;
}
export const SocialButtons: FC<IProps> = ({ googleLabel, facebookLabel, classNameBtn, classNameWrapper }) => {
  return (
    <div className={clsx(classes.socialBlock, classNameWrapper)}>
      <button className={clsx(classes.btnSocial, classNameBtn)} aria-label="Facebook" disabled>
        <Icon type="facebook" size={22} />
        {facebookLabel}
      </button>
      <button className={clsx(classes.btnSocial, classNameBtn)} aria-label="Google" disabled>
        <Icon type="google" size={22} />
        {googleLabel}
      </button>
    </div>
  );
};
