import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import classes from './Tabs.module.scss';

interface ITabsProps {
  children: ReactNode;
  classNameWrapper?: string;
}

export const Tabs: FC<ITabsProps> = ({ classNameWrapper, children }) => {
  return <div className={clsx(classes.tabs, classNameWrapper)}>{children}</div>;
};

interface ITabProps {
  label: string;
  index: number;
  selectedValue?: number;
  onClick: (index: number) => void;
  disabled?: boolean;
}

export const Tab: FC<ITabProps> = ({ label, index, selectedValue, disabled, onClick }) => {
  const isSelected = index === selectedValue;

  return (
    <button
      className={clsx(classes.tab, { [classes.selected]: isSelected, [classes.disabled]: disabled })}
      onClick={() => onClick(index)}
      disabled={disabled}
      aria-label={label}
    >
      {label}
    </button>
  );
};
