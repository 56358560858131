import { API_URL } from '@lib/constants/environment';
import { handleError } from '@utils/error-handling';

export interface IChangePasswordFormValues {
  currentPassword: string;
  newPassword: string;
}

export async function postChangePassword(data: IChangePasswordFormValues, token?: string): Promise<void> {
  const res = await fetch(`${API_URL}/account/change-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token || ''
    },
    body: JSON.stringify(data),
    cache: 'no-cache'
  });
  return handleError<void>(res);
}
