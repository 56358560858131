import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { Modal } from '@components';
import { useDeliveryAddress } from '@contexts/delivery-address';
import { ILocation } from '@types';
import { formatLocationLabel } from '@utils/formatting';
import { PlacesAutocomplete } from '../../shared/form-elements';
import { Icon } from '../../shared/icon/Icon';

import classes from './PlacesAutocompleteModal.module.scss';

interface IProps {
  className?: string;
}

const PlacesAutocompleteModal = ({ className }: IProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { lastUsedDeliveryAddress } = useDeliveryAddress();
  const [address, setAddress] = useState(lastUsedDeliveryAddress);

  useEffect(() => {
    if (lastUsedDeliveryAddress) {
      setAddress(lastUsedDeliveryAddress);
    }
  }, [lastUsedDeliveryAddress]);

  function closeModal() {
    setModalOpen(false);
  }

  function openModal() {
    setModalOpen(true);
  }

  function submitAddress(newAddress: ILocation) {
    setAddress(newAddress);
    closeModal();
  }

  return (
    <>
      <Modal open={modalOpen} closeModal={closeModal}>
        <h3 className={classes.dialogTitle}>Виберіть ваше місто</h3>
        <PlacesAutocomplete address={address} setAddress={submitAddress} inputLabel="Введіть своє місто" />
      </Modal>
      <div onClick={openModal} className={clsx(classes.triggerAddressDialog, { [className || '']: className })}>
        {address ? formatLocationLabel(address) : 'Ваше місто'}&nbsp;
        <Icon className={classes.arrowExpand} type="arrowExpand" width={10} leftOffset={4} />
      </div>
    </>
  );
};

export { PlacesAutocompleteModal };
